import React, { PropsWithChildren } from "react";
import { Button, Space, Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { BellOutlined, FieldTimeOutlined } from "@ant-design/icons";

import Text from "antd/es/typography/Text";
import moment from "moment";
import { formatSeconds } from "../../../utils/common";
import { Notification } from "../../UserNotifications/UserNotifications";
import TrimmedTitle from "../../Shared/TrimmedTitle";
import { UserFilledIcon } from "../../Shared/UserFillled";
import { UserOutlinedIcon } from "../../Shared/UserOutlined";
import { UserIcon } from "../../Shared/UserIcon";

interface IProps {
  data: Notification[];
  isRequest: boolean;
  readNotification: (id: number) => void;
}
const NotificationsList = (props: IProps): JSX.Element => {
  const { data, isRequest, readNotification } = props;

  const makeTicketUrl = (notification: Notification) => {
    let url = `/group/${notification.ticket?.group?.id}/${notification.ticket?.id}`;

    if (notification.history) url += `/e/${notification.history.id}`;
    if (notification.comment) url += `/c/${notification.comment.id}`;
    if (notification.hiddenComment)
      url += `/h/${notification.hiddenComment.id}`;

    return url;
  };
  const makeBopUrl = (notification: Notification) => {
    let url = `/bop/${notification.bop?.id}`;

    if (notification.bopHistory) url += `/e/${notification.bopHistory.id}`;
    if (notification.bopComment) url += `/c/${notification.bopComment.id}`;

    return url;
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (createdAt: number) => {
        return moment(createdAt * 1000).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      title: "Od",
      dataIndex: "creator",
      key: "creator",
      width: 200,
      render: (creator: any) => {
        if (creator?.typeAccount === 1) {
          return (
            <Space direction="horizontal">
              <UserIcon user={creator} />
              <Space direction="horizontal">
                <span>
                  {creator.name} {creator.surname}
                </span>
              </Space>
            </Space>
          );
        }

        if (creator?.typeAccount === 2) {
          return (
            <Space direction="horizontal">
              <UserIcon user={creator} />
              <Space direction="horizontal">
                <span>
                  {creator.name} {creator.surname}
                </span>
              </Space>
            </Space>
          );
        }

        return "jobin";
      },
    },
    {
      title: "Powiadomienie",
      dataIndex: "description",
      key: "description",
      render: (description: string, notification: Notification) => {
        return (
          <>
            {notification.source === "ticket" && (
              <Link
                to={makeTicketUrl(notification)}
                className="item__link"
                onClick={() => readNotification(notification?.id)}
              >
                <TrimmedTitle
                  title={`#${notification.ticket.displayId} - ${notification.ticket.title}`}
                />
              </Link>
            )}

            {notification.source === "bop" && (
              <Link
                to={makeBopUrl(notification)}
                replace
                className="item__link"
                onClick={() => {
                  readNotification(notification.id);
                }}
              >
                <TrimmedTitle
                  title={`#${notification.bop.id} - BOP - ${notification.bop.title}`}
                />{" "}
                {notification.count && notification.count > 0 && (
                  <span
                    style={{
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    (<BellOutlined style={{ marginRight: 2 }} />
                    <span>× {notification.count})</span>
                  </span>
                )}
              </Link>
            )}

            {notification.source === "chat" && (
              <Link
                to={
                  notification.chat
                    ? `/czat/${notification.chat?.room?.id}${notification.chat?.id}`
                    : "/czat"
                }
                className="item__link"
                onClick={() => readNotification(notification?.id)}
              >
                Czat
              </Link>
            )}

            {notification.source === "archive" && (
              <Link
                to="/archive"
                className="item__link"
                onClick={() => readNotification(notification?.id)}
              >
                Archiwum
              </Link>
            )}
            <p className="item__text">{notification.description}</p>
          </>
        );
      },
    },
  ];
  return (
    <Table
      style={{ width: "100%" }}
      locale={{ emptyText: "Brak danych" }}
      size="middle"
      dataSource={data}
      columns={columns}
      pagination={{ position: ["bottomRight"], pageSize: 20 }}
      loading={isRequest}
      className="notifications-page__table table"
      rowKey="id"
      rowClassName={(record, index) => {
        if (!record.isRead) {
          return "table__row table__row--unread";
        }
        return "table__row";
      }}
    />
  );
};

export default NotificationsList;
