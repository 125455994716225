import { Badge, Col, Row } from "antd";
import {
  BookOutlined,
  DollarCircleOutlined,
  LockOutlined,
  PhoneOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";
import TicketTypeForm from "../../Pages/Ticket/QuickForms/TicketTypeForm";
import TicketType from "../TicketType";
import TrimmedTitle from "../TrimmedTitle";
import { TicketStatus } from "../../../types/ticket";

interface ISearchTicket {
  ticket: any;
  onClick: () => void;
}

const SearchTicket = (props: ISearchTicket) => {
  const { ticket, onClick } = props;

  return (
    <Row gutter={[8, 8]} align="middle" style={{ padding: 4 }}>
      <Col xs={8} sm={6} md={6} lg={6} xl={6} xxl={5}>
        <div>
          <TicketType ticketType={ticket.ticketType} />
        </div>
      </Col>

      <Col xs={16} sm={18} md={18} lg={18} xl={18} xxl={19}>
        {ticket?.highlight > 0 && (
          <BookOutlined style={{ color: "green", marginRight: 2 }} />
        )}
        {ticket?.favorite > 0 && (
          <StarOutlined style={{ color: "orange", marginRight: 2 }} />
        )}
        {ticket?.callclient > 0 && (
          <PhoneOutlined style={{ color: "crimson", marginRight: 2 }} />
        )}
        {ticket?.pay === 1 && (
          <DollarCircleOutlined style={{ marginRight: 4 }} />
        )}
        {ticket?.status_id?.id === TicketStatus.CLOSED && (
          <LockOutlined style={{ color: "red", marginRight: 4 }} />
        )}
        <Link
          to={`/group/${ticket?.group?.id}/${ticket.id}`}
          className="jb-link jb-link--secendary"
          onClick={onClick}
        >
          <span className="">
            <TrimmedTitle title={`#${ticket.displayId} - ${ticket.title}`} />
          </span>
        </Link>
        <span
          style={{
            marginLeft: 6,
          }}
        >
          {!ticket.isVisited && (
            <Badge
              count="Nowy"
              size="default"
              style={{
                backgroundColor: "crimson",
                marginLeft: 2,
                marginBottom: 2,
              }}
            />
          )}
          <Badge
            count={ticket.newComments}
            size="default"
            style={{
              backgroundColor: "rgb(219, 149, 35)",
              marginLeft: 2,
              marginBottom: 2,
            }}
          />
        </span>
      </Col>
    </Row>
  );
};

export default SearchTicket;
