import React from "react";
import { Helmet } from "react-helmet";

interface IProps {
  displayBadge: boolean;
  title: string;
}

export const MetaTitle = (props: IProps) => {
  const { title, displayBadge } = props;

  const display = `${displayBadge ? `❗️` : ""}${title} | jobin.to`;

  return <Helmet title={display} defer={false} />;
};
