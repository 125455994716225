import React, { PropsWithChildren } from "react";
import { Button, Space, Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
  FieldTimeOutlined,
  LockOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";

import Text from "antd/es/typography/Text";
import moment from "moment";
import { formatMoney, formatSeconds } from "../../../utils/common";
import { UserFilledIcon } from "../../Shared/UserFillled";
import { UserOutlinedIcon } from "../../Shared/UserOutlined";
import TrimmedTitle from "../../Shared/TrimmedTitle";
import { UserIcon } from "../../Shared/UserIcon";

const SingleMonth = (props: PropsWithChildren<any>): JSX.Element => {
  const { data, summary, isRequest, month, users, totals, showCost } = props;
  const columns = [
    {
      title: "Tytuł",
      dataIndex: "title",
      className: "single-month__column--title",
      key: "title",
      render: (title: string, worktime: any) => {
        const status = worktime?.ticket?.status_id.id;
        return (
          <Link
            to={`/group/${1}/${worktime.ticket.id}`}
            className="jb-link jb-link--secendary"
          >
            <Space className="ant-space-break-words">
              {status === 8 || status === 9 ? (
                <LockOutlined color="red" style={{ color: "red" }} />
              ) : (
                <UnlockOutlined style={{ color: "green" }} />
              )}
              <Text>
                <TrimmedTitle
                  title={`#${worktime.ticket.displayId} - ${worktime.ticket.title}`}
                />
              </Text>
            </Space>
          </Link>
        );
      },
    },
    {
      title: "Data dodatnia",
      dataIndex: "addDate",
      key: "addDate",
      render: (name: string, worktime: any) => (
        <Text>
          {moment(worktime?.ticket?.addDate * 1000).format("DD.MM.Y HH:mm")}
        </Text>
      ),
    },
    {
      title: "Czas ost. akcji",
      dataIndex: "end",
      key: "end",
      render: (end: number, worktime: any) => (
        <Text>{moment(end * 1000).format("DD.MM.Y HH:mm")}</Text>
      ),
    },
    {
      title: "Użytkownik",
      className: "single-month__column--users",
      dataIndex: "users",
      key: "users",
      render: (usersObj: any[], worktime: any) => {
        return (
          <Space direction="vertical">
            {Object.values(usersObj).map((obj: any) => (
              <Space direction="horizontal">
                <UserIcon
                  leaders={worktime.ticket?.group?.leaders}
                  user={obj.user}
                />

                <Space direction="horizontal">
                  <span>
                    {obj.user.name} {obj.user.surname}
                  </span>
                </Space>
              </Space>
            ))}
          </Space>
        );
      },
    },
    {
      title: "Godz/Min/Sek",

      dataIndex: "seconds",
      key: "seconds",
      render: (seconds: string) => {
        return (
          <Tooltip title={formatSeconds(parseInt(seconds, 10), true)}>
            <Text>{formatSeconds(parseInt(seconds, 10))}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Koszt",
      hidden: !showCost,
      className: "single-month__column--cost",
      dataIndex: "cost",
      key: "cost",
      render: (cost: number) => {
        return <Text>{formatMoney(cost)}</Text>;
      },
    },
    {
      title: "Stawka godz.",
      hidden: !showCost,
      className: "single-month__column--hour-cost",
      dataIndex: "costHour",
      key: "costHour",
      render: (costHour: number) => {
        return <Text>{formatMoney(costHour)}</Text>;
      },
    },
    {
      title: "Postęp",
      dataIndex: "progress",
      ellipsis: true,
      key: "progress",
      render: (progress: string, worktime: any) => {
        return <Text>{worktime?.ticket?.progress || 0} %</Text>;
      },
    },
    {
      title: "Szczegóły",
      dataIndex: "action",
      ellipsis: false,
      key: "action",
      render: (progress: string, worktime: any) => {
        const groupId = worktime.ticket.group.id;
        const { id } = worktime.ticket;
        return (
          <Link to={`/group/${groupId}/${id}/worktimes`}>
            <Button icon={<FieldTimeOutlined />} />
          </Link>
        );
      },
    },
  ];
  return (
    <Table
      title={() => (
        <div className="single-month__title">
          {moment(month, "MM-YYYY").format("MMMM YYYY")}
        </div>
      )}
      className="single-month"
      style={{ width: "100%" }}
      scroll={{ x: true }}
      locale={{ emptyText: "Brak danych" }}
      size="small"
      dataSource={data}
      columns={columns.filter((item) => !item.hidden)}
      pagination={false}
      loading={isRequest}
      summary={() => (
        <>
          <Table.Summary.Row className="single-month__summary--month">
            <Table.Summary.Cell index={1} />
            <Table.Summary.Cell index={2} />
            <Table.Summary.Cell index={3}>
              <Text strong>Podsumowanie</Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}>
              <Text strong style={{ textTransform: "capitalize" }}>
                {moment(month, "MM-YYYY").format("MMMM YYYY")}
              </Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              <Tooltip
                title={formatSeconds(parseInt(summary.seconds, 10), true)}
              >
                <Text strong>
                  {formatSeconds(parseInt(summary.seconds, 10))}
                </Text>
              </Tooltip>
            </Table.Summary.Cell>
            {showCost && (
              <Table.Summary.Cell index={6}>
                <Text strong>{formatMoney(summary.cost)}</Text>
              </Table.Summary.Cell>
            )}
            <Table.Summary.Cell index={7} />
            <Table.Summary.Cell index={8} />
            <Table.Summary.Cell index={9} />
          </Table.Summary.Row>
          {Object.values(users).map((userObj: any) => (
            <Table.Summary.Row className="single-month__summary--day">
              <Table.Summary.Cell index={1} />
              <Table.Summary.Cell index={2} />
              <Table.Summary.Cell index={3} />
              <Table.Summary.Cell index={4}>
                <Text strong style={{ textTransform: "capitalize" }}>
                  {userObj.user.name} {userObj.user.surname}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Tooltip
                  title={formatSeconds(parseInt(userObj.seconds, 10), true)}
                >
                  <Text strong>
                    {formatSeconds(parseInt(userObj.seconds, 10))}
                  </Text>
                </Tooltip>
              </Table.Summary.Cell>
              {showCost && (
                <Table.Summary.Cell index={6}>
                  <Text strong>{formatMoney(userObj.cost)}</Text>
                </Table.Summary.Cell>
              )}
              <Table.Summary.Cell index={7} />
              <Table.Summary.Cell index={8} />
              <Table.Summary.Cell index={9} />
            </Table.Summary.Row>
          ))}
          {totals && (
            <>
              <Table.Summary.Row className="single-month__summary--total">
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3}>
                  <Text strong>Suma</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} />
                <Table.Summary.Cell index={5}>
                  <Tooltip title={formatSeconds(totals.total.seconds, true)}>
                    <Text strong>{formatSeconds(totals.total.seconds)}</Text>
                  </Tooltip>
                </Table.Summary.Cell>
                {showCost && (
                  <Table.Summary.Cell index={6}>
                    <Text strong>{formatMoney(totals.total.cost)}</Text>
                  </Table.Summary.Cell>
                )}
                <Table.Summary.Cell index={7} />
                <Table.Summary.Cell index={8} />
                <Table.Summary.Cell index={9} />
              </Table.Summary.Row>
              {Object.values(totals.types).map((type: any) => {
                return Object.values(type).map((ticketType: any) => (
                  <Table.Summary.Row className="single-month__summary">
                    <Table.Summary.Cell index={1} />
                    <Table.Summary.Cell index={2} />
                    <Table.Summary.Cell index={3} />
                    <Table.Summary.Cell index={4}>
                      <Text strong>#{ticketType.name}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      <Tooltip title={formatSeconds(ticketType.seconds, true)}>
                        <Text strong>{formatSeconds(ticketType.seconds)}</Text>
                      </Tooltip>
                    </Table.Summary.Cell>
                    {showCost && (
                      <Table.Summary.Cell index={6}>
                        <Text strong>{formatMoney(ticketType.cost)}</Text>
                      </Table.Summary.Cell>
                    )}
                    {showCost && (
                      <Table.Summary.Cell index={7}>
                        <Text strong>{formatMoney(ticketType.costHour)}</Text>
                      </Table.Summary.Cell>
                    )}
                    <Table.Summary.Cell index={8} />
                    <Table.Summary.Cell index={9} />
                  </Table.Summary.Row>
                ));
              })}
            </>
          )}
        </>
      )}
      rowKey="id"
    />
  );
};

export default SingleMonth;
