import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Card, Col, Row, Space, Table, Tag } from "antd";
import { Skeleton } from "antd/es";
import Text from "antd/es/typography/Text";
import moment from "moment";
import {
  makeAvatarName,
  stringToColour,
  timeElapsedFromTimestamp,
  timestampToDate,
} from "../../../utils/common";
import TrimmedTitle from "../TrimmedTitle";

interface IUserAvatar {
  image: string | null;
  avatarColor: string;
  avatarBackground: string;
  name: string;
  surname: string;
}

interface IActiveTask {
  number: number;
  name: string;
  user: IUserAvatar;
  startDate: number;
  ticketId: number;
  displayId: number;
}

const ActiveTasks = (props: any): JSX.Element => {
  const { data, isLoading, isMobile } = props;
  const [taskTimer, setTaskTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setTaskTimer(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const dataSource = data?.map((worktime: any, index: number) => {
    const row = {
      key: worktime.id,
      number: index + 1,
      name: worktime.ticket?.title,
      ticketId: worktime.ticket?.id,
      displayId: worktime.ticket?.displayId,
      startDate: worktime.dateStart * 1000,
      user: {
        image: worktime.user.img,
        avatarColor: "white",
        avatarBackground: stringToColour(worktime.user.email),
        name: worktime.user.name,
        surname: worktime.user.surname,
      },
    };
    return row;
  });

  const UserAvatar = (user: IUserAvatar) => {
    const { image, avatarColor, avatarBackground, name, surname } = user;
    return (
      <Avatar
        size={40}
        src={image || null}
        className="user-app__avatar"
        style={{
          color: avatarColor,
          backgroundColor: avatarBackground,
          borderColor: "#fefefe",
          borderWidth: 1,
          borderStyle: "solid",
        }}
      >
        {makeAvatarName(false, name, surname)}
      </Avatar>
    );
  };

  let columns = [
    {
      title: "",
      dataIndex: "user",
      key: "mobileRow",
      hidden: !isMobile,
      render: (user: IUserAvatar, task: IActiveTask) => {
        const { image, avatarColor, avatarBackground, name, surname } = user;

        return (
          <>
            <Row
              style={{ width: "100%" }}
              justify="space-between"
              align="middle"
            >
              <Space>
                <UserAvatar
                  image={image}
                  avatarColor={avatarColor}
                  avatarBackground={avatarBackground}
                  name={name}
                  surname={surname}
                />
              </Space>
              <Text
                style={{ marginLeft: 16, marginRight: "auto" }}
              >{`${name} ${surname}`}</Text>
              <div>{timeElapsedFromTimestamp(task.startDate)}</div>
            </Row>
            <Row
              style={{ width: "100%" }}
              justify="space-between"
              align="middle"
            >
              <Text style={{ paddingLeft: 56 }}>
                <Row>
                  <Col span={24}>
                    <small>
                      <Link to={`group/1/${task.ticketId}`}>
                        <TrimmedTitle
                          title={`#${task.displayId} - ${task.name}`}
                        />
                      </Link>
                    </small>
                  </Col>
                  <Col span={24}>
                    <small>
                      Data rozpoczęcia:{" "}
                      {timestampToDate(task.startDate, "dd.MM.yyyy HH:mm")}
                    </small>
                  </Col>
                </Row>
              </Text>
            </Row>
          </>
        );
      },
      width: "100%",
    },
    {
      title: "",
      dataIndex: "user",
      key: "user",
      hidden: isMobile,
      render: (user: IUserAvatar) => {
        const { image, avatarColor, avatarBackground, name, surname } = user;
        return (
          <UserAvatar
            image={image}
            avatarColor={avatarColor}
            avatarBackground={avatarBackground}
            name={name}
            surname={surname}
          />
        );
      },
      width: 60,
    },
    {
      title: "Imię Nazwisko",
      dataIndex: "user",
      key: "userName",
      hidden: isMobile,
      minWidth: 130,
      ellipsis: true,
      render: (user: IUserAvatar) => `${user.name} ${user.surname}`,
    },
    {
      title: "Wątek",
      dataIndex: "name",
      key: "name",
      hidden: isMobile,
      render: (name: string, task: IActiveTask) => {
        // TODO: Utworzyć komponent linku do zadania
        return (
          <Link to={`group/1/${task.ticketId}`}>
            <TrimmedTitle title={`#${task.displayId} - ${name}`} />
          </Link>
        );
      },
    },
    {
      title: "Data rozpoczęcia",
      dataIndex: "startDate",
      key: "startDate",
      hidden: isMobile,
      ellipsis: true,
      render: (startDate: number, record: any) => {
        return timestampToDate(startDate, "dd.MM.yyyy HH:mm");
      },
    },
    {
      title: "Czas trwania",
      dataIndex: "startDate",
      key: "startDate",
      hidden: isMobile,
      ellipsis: true,
      render: (startDate: number, record: any) => {
        return timeElapsedFromTimestamp(startDate);
      },
    },
  ];

  columns = columns.filter((row: any) => !row.hidden);
  return (
    <Card
      title="Aktywne wątki"
      className="active-tasks"
      bodyStyle={{ padding: isLoading ? 20 : 0 }}
    >
      {!isLoading ? (
        <Table
          locale={{ emptyText: "Brak danych" }}
          dataSource={dataSource}
          className="active-tasks__table"
          columns={columns}
          pagination={false}
          scroll={{ y: 300 }}
        />
      ) : (
        <Skeleton active paragraph />
      )}
    </Card>
  );
};

export default ActiveTasks;
