import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ConfigProvider } from "antd";

import { Helmet } from "react-helmet";

import locale from "antd/es/locale/pl_PL";
import moment from "moment";
import "moment/locale/pl";

import Login from "./components/Auth/Login";
import "./app.scss";
import PrivateRoute from "./components/PrivateRoute";
import DashboardBase from "./components/DashboardBase";
import DashboardPage from "./components/Pages/Dashboard";
import UsersPage from "./components/Pages/Users";
import TaskGroupsPage from "./components/Pages/TicketGroups";
import store from "./reducers/store";
import UserEditPage from "./components/Pages/Users/edit";
import TicketGroupEdit from "./components/Pages/TicketGroups/edit";
import HomePage from "./components/Pages/Home";
import TaskGroupPage from "./components/Pages/TicketGroup";
import ScrollToTop from "./components/Shared/ScrollToTop";
import TicketPage from "./components/Pages/Ticket";
import NewTicket from "./components/Pages/Ticket/new";
import EditTicket from "./components/Pages/Ticket/edit";
import CalendarPage from "./components/Pages/Calendar";
import TicketGroupsWorktimesPage from "./components/Pages/TicketGroupsWorktimes";
import TicketGroupWorktimesPage from "./components/Pages/TicketGroupWorktimes";
import TicketWorktimesPage from "./components/Pages/TicketWorktimes";
import ArchivePage from "./components/Pages/Archive";
import NotificationsPage from "./components/Pages/Notifications";
import AssignedPage from "./components/Pages/Assigned";
import ChatPage from "./components/Pages/Chat";
import useIsMobile from "./hooks/useIsMobile";
import EmptyPage from "./components/DashboardBase/EmptyPage";
import AudioService from "./services/audio-service";
import DuplicateTicket from "./components/Pages/Ticket/duplicate";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPasswordForm from "./components/Auth/ResetPassword/ResetPasswordForm";
import ResetPassword from "./components/Auth/ResetPassword";
import UserProfilePage from "./components/Pages/Profile";
import Invite from "./components/Auth/Invite";
import BopPage from "./components/Pages/Bop";
import NewBop from "./components/Pages/Bop/new";
import BopDetailPage from "./components/Pages/Bop/detail";

moment.locale("pl");

function App(): JSX.Element {
  AudioService.getInstance();

  return (
    <Provider store={store}>
      <Router>
        <Helmet title="Jobin" defer={false} />
        <ScrollToTop />
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/password_reset/:token">
            <ResetPassword />
          </Route>
          <Route path="/invite/:token">
            <Invite />
          </Route>
          <Route path="/" exact>
            <PrivateRoute>
              <DashboardBase title="Strona główna" subtitle="Panel zarządzania">
                <HomePage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/profil" exact>
            <PrivateRoute>
              <DashboardBase title="Użytkownik" subtitle="Edycja profilu">
                <UserProfilePage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/kokpit" exact>
            <PrivateRoute>
              <DashboardBase title="Kokpit" subtitle="Panel zarządzania">
                <DashboardPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>
          <Route path="/archive" exact>
            <PrivateRoute>
              <DashboardBase title="Archiwum" subtitle="Panel zarządzania">
                <ArchivePage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/users" exact>
            <PrivateRoute>
              <DashboardBase title="Użytkownicy" subtitle="Panel zarządzania">
                <UsersPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/users/edit/:id/" exact>
            <PrivateRoute>
              <DashboardBase
                title="Edycja użytkownika"
                subtitle="Panel zarządzania"
              >
                <UserEditPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/groups" exact>
            <PrivateRoute>
              <DashboardBase title="Działy" subtitle="Panel zarządzania">
                <TaskGroupsPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/groups/:id/edit" exact>
            <PrivateRoute>
              <DashboardBase
                title="Edycja działów"
                subtitle="Panel zarządzania"
              >
                <TicketGroupEdit />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path={["/group/:groupId", "/groups/:groupId"]} exact>
            <PrivateRoute>
              <DashboardBase title="Dział" subtitle="Dział">
                <TaskGroupPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/group/:groupId/new-ticket" exact>
            <PrivateRoute>
              <DashboardBase title="Ticket" subtitle="Nowy">
                <NewTicket />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/group/:groupId/worktimes" exact>
            <PrivateRoute>
              <DashboardBase title="Zestawienie" subtitle="Panel zarządzania">
                <TicketGroupWorktimesPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route
            path={[
              "/group/:groupId/:id",
              "/group/:groupId/:id/e/:eventId",
              "/group/:groupId/:id/c/:commentId",
              "/group/:groupId/:id/h/:hiddenId",
            ]}
            exact
            render={(props) => {
              return (
                <>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <PrivateRoute {...props}>
                    <DashboardBase
                      title="Wątek"
                      subtitle="Moje wątki"
                      key={new Date().getTime()}
                    >
                      <TicketPage />
                    </DashboardBase>
                  </PrivateRoute>
                </>
              );
            }}
          />

          <Route path="/group/:groupId/:id/worktimes" exact>
            <PrivateRoute>
              <DashboardBase
                title="Zestawienie czasu pracy w wątku"
                subtitle="Panel zarządzania"
              >
                <TicketWorktimesPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/group/:groupId/:id/edit">
            <PrivateRoute>
              <DashboardBase title="Ticket" subtitle="Edycja">
                <EditTicket />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/group/:groupId/:id/duplicate">
            <PrivateRoute>
              <DashboardBase title="Ticket" subtitle="Duplikat">
                <DuplicateTicket />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/calendar" exact>
            <PrivateRoute>
              <DashboardBase title="Kalendarz" subtitle="Terminy">
                <CalendarPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/assigned" exact>
            <PrivateRoute>
              <DashboardBase
                title="Przydzielone"
                subtitle="Przydzielone do mnie"
              >
                <AssignedPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/notifications" exact>
            <PrivateRoute>
              <DashboardBase title="Powiadomienia" subtitle="Panel zarządzania">
                <NotificationsPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/czat" exact>
            <PrivateRoute>
              <DashboardBase
                title="Czat"
                subtitle="Wiadomości grupowe"
                displayContentOnly
                displayContentOnlyMobile
                hideScrollToTop
              >
                <ChatPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/czat/:room/:id">
            <PrivateRoute>
              <DashboardBase
                title="Czat"
                subtitle="Wiadomości grupowe"
                displayContentOnly
                displayContentOnlyMobile
                hideScrollToTop
                key={new Date().getTime()}
              >
                <ChatPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/bop" exact>
            <PrivateRoute>
              <DashboardBase title="BOP" subtitle="Biuro obsługi petenta">
                <BopPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/bop/new" exact>
            <PrivateRoute>
              <DashboardBase title="Bop" subtitle="Nowy">
                <NewBop />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route
            path={[
              "/bop/:id",
              "/bop/:id/e/:eventId",
              "/bop/:id/c/:commentId",
              "/bop/:id/h/:hiddenId",
            ]}
            exact
            render={(props) => {
              return (
                <>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <PrivateRoute {...props}>
                    <DashboardBase
                      title="Zgłoszenia"
                      subtitle="BOP"
                      key={new Date().getTime()}
                    >
                      <BopDetailPage />
                    </DashboardBase>
                  </PrivateRoute>
                </>
              );
            }}
          />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
