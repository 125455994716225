/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react";
import { Input, Space } from "antd";
import {
  CloseOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import classnames from "classnames";

interface ISearchInputProps {
  isMobile: boolean;
  isToggled: boolean;
  isOpen: boolean;
  isSearching: boolean;
  toggleSearch: () => void;
  onChange: (query: string) => void;
  value: string;
  setOpen: (value: boolean) => void;
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}
const SearchInput = (props: ISearchInputProps) => {
  const {
    isMobile,
    isOpen,
    setOpen,
    isToggled,
    isSearching,
    toggleSearch,
    onChange,
    value,
    handleKeyPress,
  } = props;

  return (
    <Space className="search__container">
      {(!isMobile || (isMobile && isToggled)) && (
        <Input
          value={value}
          allowClear
          onChange={(event) => onChange(event.target.value)}
          onKeyPress={(event) => handleKeyPress(event)}
          onFocus={() => setOpen(true)}
          placeholder="Szukaj..."
          prefix={
            isSearching ? (
              <LoadingOutlined spin />
            ) : (
              <SearchOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            )
          }
          className={classnames({
            search__input: true,
            "search__input--mobile": isMobile,
            "search__input--open": isOpen,
          })}
        />
      )}

      {isMobile && isToggled && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className="user-notifications__link" onClick={toggleSearch}>
          <CloseOutlined style={{ fontSize: "20px", color: "black" }} />
        </a>
      )}
    </Space>
  );
};

export default SearchInput;
