import React, { PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";

import "moment/locale/pl";

import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Grid,
  Input,
  Progress,
  Row,
  Skeleton,
  Space,
  Tabs,
  Tooltip,
} from "antd";

import {
  CalendarOutlined,
  FieldTimeOutlined,
  FilterOutlined,
  NotificationOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import TicketService from "../../../services/tickets-service";
import TaskGroupsService from "../../../services/taskgroups-service";
import { AppState } from "../../../reducers";
import List from "./list";
import { formatMoney } from "../../../utils/common";

import {
  clearTicketGroupState,
  getTicketGroup,
  getTicketGroupTicketTypes,
} from "../../../actions/ticketgroups";
import {
  setArchivedCount,
  setCurrentEntityForbidden,
  setCurrentGroupTitle,
} from "../../../actions/global";
import CalendarPicker from "../../Shared/CustomFilters/CalendarPicker";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import BopService from "../../../services/bop-service";

const { TabPane } = Tabs;

interface IRouteParams {
  groupId: string;
}

const BopPage = (props: PropsWithChildren<any>): JSX.Element => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { useBreakpoint } = Grid;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const screens = useBreakpoint();
  const { displayNotification } = props;
  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setIsMobile(!isMdBreakpoint);
  }, [screens, setIsMobile]);

  useEffect(() => {
    setIsLoading(true);
    BopService.getList()
      .then((response: AxiosResponse) => {
        setList(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <Can renderError type="bop_view">
      <div className="calendar-page">
        <MetaTitle title="BOP" displayBadge={displayNotification} />
        <Can type="bop_create">
          <Row gutter={16}>
            <Col span={24}>
              <Alert
                style={{ marginBottom: 16 }}
                message={
                  <>
                    <p style={{ margin: 0 }}>
                      Cześć! Fajnie, że próbujesz z naszym cyfrowym pomocnikiem
                      :) Jak już pewnie wiesz nie jesteśmy sztywnym korpo, a{" "}
                      <a href="https://jobin.to/o-nas">
                        <strong>grupą specjalistów</strong>
                      </a>
                      , którzy stworzyli coś pomocnego. Jeśli chcesz zgłosić
                      jakiś błąd, problem lub masz pomysł jak możemy ulepszyć
                      jobin.to -{" "}
                      <a href="https://www.youtube.com/watch?v=zXbGaKBgFaQ">
                        <strong>załóż swojego BOPA</strong>
                      </a>
                      !
                    </p>
                  </>
                }
                type="warning"
              />
            </Col>
            <Col span={24}>
              <div className="users-page__header-actions">
                <Link to="/bop/new">
                  <Button type="primary">Nowe zgłoszenie</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Can>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <Space
              direction="vertical"
              style={{ marginLeft: "auto", width: "100%" }}
            >
              <Card bordered={false} size="small">
                <List tasks={list} isRequest={isLoading} isMobile={isMobile} />
              </Card>
            </Space>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  setArchivedCountAction: setArchivedCount,
};

const mapStateToProps = (state: AppState) => {
  return {
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BopPage);
