import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Card, Col, Row, Tooltip } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { AppState } from "../../../reducers";
import {
  mergeByProperty,
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";

import {
  getTicketGroup,
  getTicketGroupLeaders,
  getTicketGroupMembers,
  getTicketGroups,
  getTicketGroupTicketTypes,
} from "../../../actions/ticketgroups";

import {
  createTicket,
  getTicket,
  updateTicket,
} from "../../../actions/tickets";
import {
  setCurrentGroupTitle,
  setCurrentTicketTitle,
} from "../../../actions/global";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import DuplicateTicketForm, {
  IDuplicateTicketFormValues,
} from "./DuplicateTicketForm";
import { getUsers } from "../../../actions/users";
import TicketService from "../../../services/tickets-service";

interface IRouteParams {
  id: string;
  groupId: string;
}
interface IDuplicateTicketPageProps {
  ticket: any;

  loggedUser: any;
  users: any[];
  leaders: any[];
  members: any[];
  ticketGroup: any;
  ticketGroups: any[];
  ticketTypes: any[];
  isTicketLoading: boolean;
  isGroupLoading: boolean;
  isTicketTypesLoading: boolean;
  isTicketGroupsLoading: boolean;
  isUpdateRequest: boolean;
  displayNotification: boolean;
  getTicketGroupAction: (id: string | number) => void;
  getTicketGroupsAction: (noLoader: boolean) => Promise<any>;
  getTicketTypesAction: (id: string | number) => void;
  getTicketAction: (id: string | number) => void;
  createTicketAction: (ticket: any) => Promise<any>;
  updateTicketAction: (id: string | number, ticket: any) => Promise<any>;
  setCurrentGroupTitleAction: (name: string) => void;
  setCurrentTicketTitleAction: (name: string) => void;
  getUsersAction: () => void;
  getLeadersAction: (id: string | number) => void;
  getMembersAction: (id: string | number) => void;
}

const DuplicateTicket = (
  props: PropsWithChildren<IDuplicateTicketPageProps>
): JSX.Element => {
  const { groupId, id } = useParams<IRouteParams>();
  const [ticketGroupId, setTicketGroupId] = useState<number>(
    parseInt(groupId, 10)
  );
  const [initAssigned, setInitAssigned] = React.useState<any[]>([]);
  const [availableUsers, setAvailableUsers] = React.useState<any[]>([]);
  const [ticketAttachments, setTicketAttachments] = useState<any[]>([]);
  const history = useHistory();

  const {
    loggedUser,
    ticket,
    users,
    members,
    leaders,
    ticketGroups,
    ticketTypes,
    ticketGroup,
    getLeadersAction,
    getMembersAction,
    getUsersAction,
    getTicketAction,
    getTicketGroupsAction,
    getTicketTypesAction,
    setCurrentGroupTitleAction,
    setCurrentTicketTitleAction,
    createTicketAction,
    isUpdateRequest,
    isTicketTypesLoading,
    isTicketLoading,
    isTicketGroupsLoading,
    displayNotification,
  } = props;

  const onSave = (
    values: IDuplicateTicketFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    createTicketAction({ ...values, isDuplicate: true })
      .then((response: AxiosResponse) => {
        openNotificationWithIcon("success", "Wątek skopiowany");
        history.replace(`/group/${groupId}/${response.data.id}`);
        callbackRequestCompleted();
      })
      .catch((err: AxiosError<{ detail?: string }>) => {
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon("error", err.response?.data?.detail || "");
        }
      });
  };

  // const onSave = (
  //   values: IDuplicateTicketFormValues,
  //   callbackRequestCompleted: () => void,
  //   setFormErrors: (errors: FormikErrors<any>) => void
  // ) => {
  //
  //
  //   // updateTicketAction(id, values)
  //   //   .then((response: AxiosResponse) => {
  //   //     openNotificationWithIcon("success", "Ticket zaktualizowany");
  //   //     history.replace(
  //   //       `/group/${response.data.group?.id}/${response.data.id}`
  //   //     );
  //   //     callbackRequestCompleted();
  //   //   })
  //   //   .catch((err: AxiosError<any>) => {
  //   //     callbackRequestCompleted();
  //   //     if (err.response?.status === 400) {
  //   //       const formikResponse = transformToFormikError(err);
  //   //       setFormErrors(formikResponse);
  //   //     }
  //   //
  //   //     if (err.response?.status === 403) {
  //   //       openNotificationWithIcon("error", err.response?.data?.detail);
  //   //     }
  //   //   });
  // };

  const fetchAttachments = useCallback(() => {
    // setAttachmentsLoading(true);
    TicketService.getTicketAttachments(id)
      .then((response) => {
        setTicketAttachments(
          response.data.filter((file: any) => file.isAttachedToTicket)
        );
      })
      .finally(() => {
        // setAttachmentsLoading(false);
      });
  }, [id, setTicketAttachments]);

  useEffect(() => {
    if (ticket) {
      setCurrentGroupTitleAction(ticket?.group?.name);
      setCurrentTicketTitleAction(`#${ticket?.displayId} - ${ticket?.title}`);
    }
  }, [setCurrentGroupTitleAction, setCurrentTicketTitleAction, ticket]);

  useEffect(() => {
    return () => {
      setCurrentGroupTitleAction("");
    };
  }, [setCurrentGroupTitleAction]);

  useEffect(() => {
    return () => {
      setCurrentTicketTitleAction("");
    };
  }, [setCurrentTicketTitleAction, ticket]);

  useEffect(() => {
    if (id) {
      getTicketAction(id);
      fetchAttachments();
    }
    getTicketGroupsAction(false);
  }, [fetchAttachments, getTicketAction, getTicketGroupsAction, id]);

  useEffect(() => {
    if (ticketGroupId) {
      getTicketTypesAction(ticketGroupId);
      getLeadersAction(ticketGroupId);
      getMembersAction(ticketGroupId);
    }
    getUsersAction();
  }, [
    getLeadersAction,
    getMembersAction,
    getTicketTypesAction,
    getUsersAction,
    ticketGroupId,
  ]);

  useEffect(() => {
    const init = ticket?.assignedUsers.map((assigned: any) => {
      console.log(assigned, "test");
      return {
        key: assigned.id,
        label: `${assigned.name} ${assigned.surname}`,
        tag: assigned.tag,
      };
    });
    // if (!init.find((assigned) => assigned.key === loggedUser.id)) {
    //   init.unshift({
    //     key: loggedUser.id,
    //     label: `${loggedUser.name} ${loggedUser.surname}`,
    //     tag: loggedUser.tag,
    //   });
    // }

    const availableUsersInit = leaders.map((leader) => {
      return leader.user;
    });
    if (
      !availableUsersInit.find((available) => available.id === loggedUser.id)
    ) {
      availableUsersInit.unshift(loggedUser);
    }

    mergeByProperty(availableUsersInit, users, "id");

    setInitAssigned(init || []);
    setAvailableUsers(availableUsersInit);
  }, [
    leaders,
    loggedUser,
    loggedUser.id,
    loggedUser.name,
    loggedUser.surname,
    ticket,
    users,
  ]);

  const canAssignUsers = Can({
    type: "ticket_can_assign_users",
    children: <></>,
  });

  const canAssignLeaders = Can({
    entity: ticketGroup,
    type: "ticket_can_assign_leaders",
    children: <></>,
  });

  return (
    <Can
      entity={ticketGroup}
      type={ticketGroup ? "loading" : "ticket_create"}
      renderError
    >
      <div className="ticket_group-edit-page">
        <MetaTitle title="Edycja wątku" displayBadge={displayNotification} />
        <Row gutter={16}>
          <Col span={24}>
            <Card bordered={false} style={{ width: "100%" }}>
              <Row gutter={16} align="middle" justify="center">
                <Col lg={24} xl={20} xxl={12}>
                  <DuplicateTicketForm
                    title={ticket?.title}
                    description={ticket?.description}
                    group={parseInt(groupId, 10)}
                    assignedUsers={initAssigned}
                    leaders={leaders}
                    members={members}
                    paid={ticket?.paid}
                    priorityId={ticket?.priority_id?.id}
                    priority={ticket?.priority_id?.id}
                    status={ticket?.status}
                    attachments={ticketAttachments}
                    cost={ticket?.cost}
                    dueDate={ticket?.dueDate}
                    statusId={ticket?.statusId}
                    canEdit={!!canAssignUsers}
                    canEditLeaders={!!canAssignLeaders}
                    users={availableUsers}
                    isLoading={
                      isTicketLoading ||
                      isTicketGroupsLoading ||
                      isTicketTypesLoading
                    }
                    ticketType={ticket?.ticketType}
                    onSave={onSave}
                    ticketTypes={ticketTypes}
                    onGroupChange={setTicketGroupId}
                    groups={ticketGroups}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketGroupAction: getTicketGroup,
  getTicketGroupsAction: getTicketGroups,
  getTicketTypesAction: getTicketGroupTicketTypes,
  getTicketAction: getTicket,
  createTicketAction: createTicket,
  updateTicketAction: updateTicket,
  setCurrentGroupTitleAction: setCurrentGroupTitle,
  setCurrentTicketTitleAction: setCurrentTicketTitle,
  getUsersAction: getUsers,
  getLeadersAction: getTicketGroupLeaders,
  getMembersAction: getTicketGroupMembers,
};

const mapStateToProps = (state: AppState) => {
  return {
    loggedUser: state.auth.logged,
    ticket: state.ticket.ticket,
    users: state.users.users,
    leaders: state.ticketgroups.ticketgroupLeaders,
    members: state.ticketgroups.ticketgroupMembers,
    ticketTypes: state.ticketgroups.ticketgroupTicketTypes,
    ticketGroup: state.ticketgroups.ticketgroup,
    ticketGroups: state.ticketgroups.ticketgroups,
    isGroupLoading: state.ticketgroups.isFetchTicketGroupRequest,
    isUsersLoading: state.users.isFetchUserRequest,
    isTicketLoading: state.ticket.isRequest,
    isLeadersLoading: state.ticketgroups.isFetchTicketGroupLeadersRequest,
    isUpdateRequest: state.ticket.isUpdateRequest,
    isTicketTypesLoading:
      state.ticketgroups.isFetchTicketGroupTicketTypesRequest,
    isTicketGroupsLoading: state.ticketgroups.isFetchTicketGroupsRequest,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateTicket);
