import React, { PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button, Card, Col, Row, Tooltip } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { EditOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { AppState } from "../../../reducers";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";

import {
  getTicketGroup,
  getTicketGroups,
  getTicketGroupTicketTypes,
} from "../../../actions/ticketgroups";

import {
  createTicket,
  getTicket,
  updateTicket,
} from "../../../actions/tickets";
import {
  setCurrentGroupTitle,
  setCurrentTicketTitle,
} from "../../../actions/global";
import EditTicketForm, { IEditTicketFormValues } from "./EditTicketForm";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";

interface IRouteParams {
  id: string;
  groupId: string;
}
interface IEditTicketPageProps {
  ticket: any;
  ticketGroup: any;
  ticketGroups: any[];
  ticketTypes: any[];
  isTicketLoading: boolean;
  isGroupLoading: boolean;
  isTicketTypesLoading: boolean;
  isTicketGroupsLoading: boolean;
  isUpdateRequest: boolean;
  displayNotification: boolean;
  getTicketGroupAction: (id: string | number) => void;
  getTicketGroupsAction: (noLoader: boolean) => Promise<any>;
  getTicketTypesAction: (id: string | number) => void;
  getTicketAction: (id: string | number) => void;
  createTicketAction: (ticket: any) => Promise<any>;
  updateTicketAction: (id: string | number, ticket: any) => Promise<any>;
  setCurrentGroupTitleAction: (name: string) => void;
  setCurrentTicketTitleAction: (name: string) => void;
}

const EditTicket = (
  props: PropsWithChildren<IEditTicketPageProps>
): JSX.Element => {
  const { groupId, id } = useParams<IRouteParams>();
  const [ticketGroupId, setTicketGroupId] = useState<number>(
    parseInt(groupId, 10)
  );
  const history = useHistory();

  const {
    ticket,
    ticketGroups,
    ticketTypes,
    getTicketAction,
    getTicketGroupsAction,
    getTicketTypesAction,
    setCurrentGroupTitleAction,
    setCurrentTicketTitleAction,
    updateTicketAction,
    isUpdateRequest,
    isTicketTypesLoading,
    isTicketLoading,
    isTicketGroupsLoading,
    displayNotification,
  } = props;

  const onSave = (
    values: IEditTicketFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    updateTicketAction(id, values)
      .then((response: AxiosResponse) => {
        openNotificationWithIcon("success", "Ticket zaktualizowany");
        history.replace(
          `/group/${response.data.group?.id}/${response.data.id}`
        );
        callbackRequestCompleted();
      })
      .catch((err: AxiosError<any>) => {
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon("error", err.response?.data?.detail);
        }
      });
  };

  useEffect(() => {
    if (ticket) {
      setCurrentGroupTitleAction(ticket?.group?.name);
      setCurrentTicketTitleAction(`#${ticket?.displayId} - ${ticket?.title}`);
    }
  }, [setCurrentGroupTitleAction, setCurrentTicketTitleAction, ticket]);

  useEffect(() => {
    return () => {
      setCurrentGroupTitleAction("");
    };
  }, [setCurrentGroupTitleAction]);

  useEffect(() => {
    return () => {
      setCurrentTicketTitleAction("");
    };
  }, [setCurrentTicketTitleAction, ticket]);

  useEffect(() => {
    if (id) {
      getTicketAction(id);
    }
    getTicketGroupsAction(false);
  }, [getTicketAction, getTicketGroupsAction, id]);

  useEffect(() => {
    if (ticketGroupId) {
      getTicketTypesAction(ticketGroupId);
    }
  }, [getTicketTypesAction, ticketGroupId]);

  return (
    <Can
      type={isTicketLoading ? "loading" : "ticket_edit"}
      entity={ticket}
      renderError
    >
      <div className="ticket_group-edit-page">
        <MetaTitle title="Edycja wątku" displayBadge={displayNotification} />
        <Row gutter={16}>
          <Col span={24}>
            <Card bordered={false} style={{ width: "100%" }}>
              <Row gutter={16} align="middle" justify="center">
                <Col lg={24} xl={20} xxl={12}>
                  <EditTicketForm
                    title={ticket?.title}
                    description={ticket?.description}
                    group={parseInt(groupId, 10)}
                    isLoading={
                      isTicketLoading ||
                      isTicketGroupsLoading ||
                      isTicketTypesLoading
                    }
                    ticketType={ticket?.ticketType}
                    onSave={onSave}
                    ticketTypes={ticketTypes}
                    onGroupChange={setTicketGroupId}
                    groups={ticketGroups}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketGroupAction: getTicketGroup,
  getTicketGroupsAction: getTicketGroups,
  getTicketTypesAction: getTicketGroupTicketTypes,
  getTicketAction: getTicket,
  createTicketAction: createTicket,
  updateTicketAction: updateTicket,
  setCurrentGroupTitleAction: setCurrentGroupTitle,
  setCurrentTicketTitleAction: setCurrentTicketTitle,
};

const mapStateToProps = (state: AppState) => {
  return {
    ticket: state.ticket.ticket,
    users: state.users.users,
    leaders: state.ticketgroups.ticketgroupLeaders,
    ticketTypes: state.ticketgroups.ticketgroupTicketTypes,
    ticketGroup: state.ticketgroups.ticketgroup,
    ticketGroups: state.ticketgroups.ticketgroups,
    isGroupLoading: state.ticketgroups.isFetchTicketGroupRequest,
    isUsersLoading: state.users.isFetchUserRequest,
    isTicketLoading: state.ticket.isRequest,
    isLeadersLoading: state.ticketgroups.isFetchTicketGroupLeadersRequest,
    isUpdateRequest: state.ticket.isUpdateRequest,
    isTicketTypesLoading:
      state.ticketgroups.isFetchTicketGroupTicketTypesRequest,
    isTicketGroupsLoading: state.ticketgroups.isFetchTicketGroupsRequest,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTicket);
