import React, { useEffect, useState } from "react";
import { Badge, Button, Dropdown, Empty, Menu } from "antd";
import {
  CaretRightOutlined,
  ClockCircleOutlined,
  LoadingOutlined,
  LockOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FormikErrors } from "formik";
import { timeElapsedFromTimestamp } from "../../utils/common";
import { endWorktime, startWorktime } from "../../actions/worktimes";
import EndWorkTimeModal from "../Pages/Ticket/QuickForms/EndWorkTimeModal";
import EndWorkTimeHeaderModal from "../Pages/Ticket/QuickForms/EndWorkTimeHeaderModal";
import { TicketStatus } from "../../types/ticket";
import TrimmedTitle from "../Shared/TrimmedTitle";
import Can from "../Shared/Can";

interface TimerTask {
  dateStart: number;
  id: number;
  title: string;
  url: string | null;
  displayId: number;
  groupId: number;
  canAddTime?: string;
  ticketStatus?: string;
  ticket?: {
    id: number;
    displayId: number;
    title: string;
    group: {
      id: number;
    };
  };
}

interface IUserTimerProps {
  timerTasks: TimerTask[];
  latest: TimerTask[];
  onStartWorktime: (id: string | number) => void;
  onEndWorktime: (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => void;
  isToggling: boolean;
}

const UserApp = (props: IUserTimerProps): JSX.Element => {
  const {
    timerTasks,
    latest,
    onStartWorktime,
    onEndWorktime,
    isToggling,
  } = props;
  const [taskTimer, setTaskTimer] = useState(0);
  const [loading, setLoading] = useState<number | string>(0);

  const canStartTime = !!Can({
    entity: null,
    type: "has_start_time_permission",
    children: <></>,
  });

  useEffect(() => {
    let interval: any;
    if (timerTasks.length) {
      interval = setInterval(() => setTaskTimer(Date.now()), 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timerTasks]);

  useEffect(() => {
    if (!isToggling) setLoading(0);
  }, [isToggling]);

  const toggleTimer = (isActive: boolean, id: string | number) => {
    setLoading(id);
    if (!isActive) onStartWorktime(id);
  };
  const renderTimerTasks = (tasks: TimerTask[], isLatest: boolean) => {
    if (!tasks.length) {
      return (
        <Empty
          description={
            isLatest ? "Brak ostatnich wątków" : "Brak aktywnych wątków"
          }
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      );
    }

    return tasks.map((task, index: number) => {
      if (index > 2) return null;

      const id = task?.ticket?.id || task.id;
      const groupId = task?.ticket?.group?.id || task.groupId;
      const displayId = task?.ticket?.displayId || task.displayId;
      const title = task?.ticket?.title || task.title;
      return (
        <li className="notifications-list__item item">
          <div className="container">
            <p className="item__text">
              <Link to={`/group/${groupId}/${id}`}>
                <TrimmedTitle title={`#${displayId} - ${title}`} />
              </Link>
            </p>
            {!isLatest && (
              <p className="item__date">
                {timeElapsedFromTimestamp(task.dateStart * 1000)}
              </p>
            )}
          </div>
          {!isLatest && (
            <EndWorkTimeHeaderModal
              onSubmit={onEndWorktime}
              activeWorktime={task}
              loading={isToggling}
            />
          )}
          {isLatest && (
            <Button
              disabled={
                (task?.ticketStatus || 0) >= TicketStatus.CLOSED ||
                task.canAddTime === "0" ||
                !canStartTime
              }
              onClick={() => toggleTimer(!isLatest, task.id)}
              icon={
                // eslint-disable-next-line no-nested-ternary
                loading === task.id ? (
                  <LoadingOutlined spin />
                ) : // eslint-disable-next-line no-nested-ternary
                isLatest ? (
                  (task?.ticketStatus || 0) >= TicketStatus.CLOSED ? (
                    <LockOutlined />
                  ) : (
                    <CaretRightOutlined />
                  )
                ) : (
                  <PauseOutlined />
                )
              }
              type={isLatest ? "default" : "primary"}
              shape="round"
            />
          )}
        </li>
      );
    });
  };

  const timerDropdown = (
    <Menu className="user-timer__timer-dropdown timer-dropdown ">
      <div className="timer-dropdown__timer-header timer-header">
        <div className="timer-header__title">
          <ClockCircleOutlined className="timer-header__icon" />
          <p>Trwające wątki</p>
        </div>
      </div>
      <ul className="timer-dropdown__timer-list timer-list">
        {renderTimerTasks(timerTasks, false)}
      </ul>
      <div className="timer-dropdown__timer-header timer-header">
        <div className="timer-header__title">
          <ClockCircleOutlined className="timer-header__icon" />
          <p>Ostatnio wykonywane</p>
        </div>
      </div>
      <ul className="timer-dropdown__timer-list timer-list timer-list--latest">
        {renderTimerTasks(latest, true)}
      </ul>
    </Menu>
  );
  return (
    <div className="user-timer">
      <Dropdown
        overlay={timerDropdown}
        placement="bottomLeft"
        arrow
        trigger={["click"]}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" className="user-notifications__link">
          <Badge
            className="user-timer__badge"
            count={
              timerTasks.length ? (
                <CaretRightOutlined className="user-timer__badge--icon" />
              ) : (
                0
              )
            }
          >
            <ClockCircleOutlined style={{ fontSize: "20px" }} />
          </Badge>
        </a>
      </Dropdown>
    </div>
  );
};

export default UserApp;
