import React, { useEffect, useState } from "react";
import { Avatar, Card, Col, Empty, Row, Skeleton } from "antd";
import { connect } from "react-redux";
import Meta from "antd/es/card/Meta";
import { FormikErrors } from "formik";
import { AxiosError, AxiosResponse } from "axios";
import { getTicketGroup, getTicketGroups } from "../../../actions/home";
import { AppState } from "../../../reducers";
import HomeSplitter from "./HomeSplitter";
import NewQuickTicketForm, {
  INewTicketQuickFormValues,
} from "../Ticket/NewQuickTicketForm";
import { createQuickTicket, updateTicket } from "../../../actions/tickets";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";
import AnnouncementForm from "../../Shared/AnnouncementForm";
import { IQuickAnnouncementValues } from "../../../types/quick-forms";
import TaskGroupsService from "../../../services/taskgroups-service";
import UsersService from "../../../services/users-service";
import { MetaTitle } from "../../Shared/MetaTitle";

interface IHomePageProps {
  loggedUser: any;
  ticketGroups: any[];
  isFetching: boolean;
  displayNotification: boolean;
  getTicketGroupsAction: () => Promise<any>;
  updateTicketAction: (id: string | number, values: any) => Promise<any>;
  getTicketGroupAction: (id: string | number) => Promise<any>;
  createQuickTicketAction: (values: any) => Promise<any>;
}
const NOT_ASSIGNED = "not_assigned";

const HomePage = (props: IHomePageProps): JSX.Element => {
  const {
    ticketGroups,
    updateTicketAction,
    getTicketGroupsAction,
    createQuickTicketAction,
    isFetching,
    loggedUser,
    getTicketGroupAction,
    displayNotification,
  } = props;
  const [splitters, setSplitters] = useState<any>({});
  const [splittersOrder, setSplittersOrder] = useState<string[]>([]);
  const [newQuickTicketVisible, setNewQuickTicketVisible] = useState<boolean>(
    false
  );
  const [newQuickTicketGroup, setNewQuickTicketGroup] = useState<number | null>(
    null
  );

  const [
    announcementFormVisible,
    setAnnouncementFormVisible,
  ] = useState<boolean>(false);

  const [announceGroupEdit, setAnnounceGroupEdit] = useState<any>(null);

  useEffect(() => {
    getTicketGroupsAction().then((r) => console.log(r));
  }, [getTicketGroupsAction]);

  useEffect(() => {
    const splittersTemp: any = {};
    const splittersOrderTemp: string[] = [];
    if (ticketGroups.length) {
      ticketGroups.forEach((ticketGroup: any) => {
        if (ticketGroup.break) {
          splittersTemp[ticketGroup.id.toString()] = {
            id: ticketGroup.id.toString(),
            title: ticketGroup.name,
            img: ticketGroup.img,
            groups: [],
          };
          splittersOrderTemp.push(ticketGroup.id.toString());
        }
      });
      splittersTemp[NOT_ASSIGNED] = {
        id: NOT_ASSIGNED,
        title: "Nieprzydzielone",
        groups: [],
      };
      splittersOrderTemp.push(NOT_ASSIGNED);

      ticketGroups.forEach((ticketGroup: any) => {
        if (!ticketGroup.break) {
          if (
            ticketGroup.parent &&
            splittersTemp[ticketGroup.parent.id.toString()]
          ) {
            splittersTemp[ticketGroup.parent.id.toString()].groups.push(
              ticketGroup
            );
          } else {
            splittersTemp[NOT_ASSIGNED].groups.push(ticketGroup);
          }
        }
      });
      setSplitters(splittersTemp);
      setSplittersOrder(splittersOrderTemp);
    }
  }, [ticketGroups]);

  const showNewQuickTicketModal = (groupId: number, visible: boolean) => {
    setNewQuickTicketGroup(groupId);
    setNewQuickTicketVisible(visible);
  };

  const showAnnouncementFormModal = (group: any, visible: boolean) => {
    setAnnounceGroupEdit(group);
    setAnnouncementFormVisible(visible);
  };

  const onMetaSave = (meta: any) => {
    return UsersService.updateMeta(meta);
  };

  const onMarkAsRead = (groupId: number) => {
    return TaskGroupsService.setAsRead(groupId);
  };

  const onQuickTicketSave = (
    values: INewTicketQuickFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    createQuickTicketAction(values)
      .then((response: AxiosResponse) => {
        openNotificationWithIcon("success", "Wątek dodany");
        getTicketGroupsAction().then((r) => console.log(r));
        setNewQuickTicketVisible(false);
        callbackRequestCompleted();
      })
      .catch((err: AxiosError) => {
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onAnnoucementSave = (
    values: IQuickAnnouncementValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    TaskGroupsService.postAnnouncement(
      announceGroupEdit.id,
      values.announcement
    )
      .then((response: AxiosResponse) => {
        getTicketGroupsAction().then((r) => console.log(r));
        setAnnounceGroupEdit(null);
        setAnnouncementFormVisible(false);
        callbackRequestCompleted();
      })
      .catch((err: AxiosError) => {
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const handleSubmitTicketType = (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => {
    const { entityId, ...vals } = values;
    updateTicketAction(entityId, vals)
      .then((res: any) => {
        // getTicketGroupAction(entityId).then((r) => console.log(r));
        onRequestComplete();
      })
      .catch((err: any) => {
        const formikResponse = transformToFormikError(err);
        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił błąd w trakcie wykonywania akcji"
          );
          if (setError) setError(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie posiadasz wystarczających uprawnień do wykonania tej akcji."
          );
          if (setError) setError(formikResponse);
        }
      });
  };

  return (
    <>
      <div className="stats-page">
        <MetaTitle
          title={`${loggedUser.company?.name || "Strona Główna"}`}
          displayBadge={displayNotification}
        />

        {isFetching && !ticketGroups.length && (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={12} xl={8}>
                <Card style={{ marginBottom: 16 }}>
                  <Skeleton loading={isFetching} avatar active>
                    <Meta
                      avatar={<Avatar src="#" />}
                      title="Card title"
                      description="This is the description"
                    />
                  </Skeleton>
                </Card>
              </Col>
              <Col xs={24} sm={24} lg={12} xl={8}>
                <Card style={{ marginBottom: 16 }}>
                  <Skeleton loading={isFetching} avatar active>
                    <Meta
                      avatar={<Avatar src="#" />}
                      title="Card title"
                      description="This is the description"
                    />
                  </Skeleton>
                </Card>
              </Col>
              <Col xs={24} sm={24} lg={12} xl={8}>
                <Card style={{ marginBottom: 16 }}>
                  <Skeleton loading={isFetching} avatar active>
                    <Meta
                      avatar={<Avatar src="#" />}
                      title="Card title"
                      description="This is the description"
                    />
                  </Skeleton>
                </Card>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} lg={12} xl={8}>
                <Card style={{ marginBottom: 16 }}>
                  <Skeleton loading={isFetching} avatar active>
                    <Meta
                      avatar={<Avatar src="#" />}
                      title="Card title"
                      description="This is the description"
                    />
                  </Skeleton>
                </Card>
              </Col>
              <Col xs={24} sm={24} lg={12} xl={8}>
                <Card style={{ marginBottom: 16 }}>
                  <Skeleton loading={isFetching} avatar active>
                    <Meta
                      avatar={<Avatar src="#" />}
                      title="Card title"
                      description="This is the description"
                    />
                  </Skeleton>
                </Card>
              </Col>
              <Col xs={24} sm={24} lg={12} xl={8}>
                <Card style={{ marginBottom: 16 }}>
                  <Skeleton loading={isFetching} avatar active>
                    <Meta
                      avatar={<Avatar src="#" />}
                      title="Card title"
                      description="This is the description"
                    />
                  </Skeleton>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {splittersOrder.map((splitterId, index: number) => {
          const splitter = splitters[splitterId];

          if (splitter.id === NOT_ASSIGNED && splitter.groups.length === 0) {
            return null;
          }
          return (
            <HomeSplitter
              splitter={splitter}
              showNewQuickTicketModal={showNewQuickTicketModal}
              showAnnouncementForm={showAnnouncementFormModal}
              onMetaSave={onMetaSave}
              onMarkAsRead={onMarkAsRead}
              getTicketGroup={getTicketGroupAction}
              handleSubmitTicketType={handleSubmitTicketType}
              userMeta={loggedUser?.meta}
            />
          );
        })}

        {!isFetching && !ticketGroups.length && (
          <Row justify="center" align="middle" style={{ minHeight: "100%" }}>
            <Col span={24} style={{ height: "100%" }}>
              <Empty description="Brak przydzielonych działów i wątków" />
            </Col>
          </Row>
        )}
      </div>
      <NewQuickTicketForm
        groupId={newQuickTicketGroup}
        visible={newQuickTicketVisible}
        onCancel={() => setNewQuickTicketVisible(false)}
        onSubmit={onQuickTicketSave}
      />

      <AnnouncementForm
        announcement={announceGroupEdit?.announcement}
        handleSubmit={(
          val: IQuickAnnouncementValues,
          onComplete: () => void,
          onError: () => void
        ) => onAnnoucementSave(val, onComplete, onError)}
        visible={announcementFormVisible}
        onCancel={() => setAnnouncementFormVisible(false)}
      />
    </>
  );
};

const mapDispatchToProps = {
  getTicketGroupsAction: getTicketGroups,
  updateTicketAction: updateTicket,
  getTicketGroupAction: getTicketGroup,
  createQuickTicketAction: createQuickTicket,
};

const mapStateToProps = (state: AppState) => {
  return {
    ticketGroups: state.home.ticketgroups,
    isFetching: state.home.ticketgroupsFetching,
    loggedUser: state.auth.logged,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
