import React, { useState } from "react";
import { Badge, Button, Dropdown, Empty, Menu } from "antd";
import { BellFilled, BellOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { Simulate } from "react-dom/test-utils";
import { groupBy } from "lodash";
import { timeAgoFromTimestamp } from "../../utils/common";
import TrimmedTitle from "../Shared/TrimmedTitle";

export interface Notification {
  id: number;
  createdAt: number;
  source: "ticket" | "chat" | "archive" | "bop";
  creator: {
    id: number;
    name: string;
    surname: string;
  };
  description: string;
  isRead: boolean;
  chat: {
    id: number;
    room: {
      id: number;
    };
  };
  comment: {
    id: number;
  };
  hiddenComment: {
    id: number;
  };
  history: {
    id: number;
  };
  bopHistory: {
    id: number;
  };
  bopComment: {
    id: number;
  };
  ticket: {
    id: number;
    displayId: number;
    title: string;
    group: {
      id: number;
    };
  };

  bop: {
    id: number;
    title: string;
  };
  url: string | null;
  count?: number;
}

interface IUserNotificationsProps {
  notifications: Notification[];
  clear: () => void;
  read: (id: number) => void;
  clearMetaTitle: () => void;
  isClearing: boolean;
}

const MAX_NOTIFICATIONS_ROWS = 20;
const MIN_GROUPED_NOTIFICATIONS = 3;

const UserNotification = (props: IUserNotificationsProps): JSX.Element => {
  const { notifications, read, clear, clearMetaTitle, isClearing } = props;

  const [visible, setVisible] = useState(false);

  const unread = notifications?.filter((notification) => !notification.isRead);

  const makeTicketUrl = (notification: Notification) => {
    let url = `/group/${notification.ticket?.group?.id}/${notification.ticket?.id}`;

    if (notification.history) url += `/e/${notification.history.id}`;
    if (notification.comment) url += `/c/${notification.comment.id}`;
    if (notification.hiddenComment)
      url += `/h/${notification.hiddenComment.id}`;

    return url;
  };
  const makeBopUrl = (notification: Notification) => {
    let url = `/bop/${notification.bop?.id}`;

    if (notification.bopHistory) url += `/e/${notification.bopHistory.id}`;
    if (notification.bopComment) url += `/c/${notification.bopComment.id}`;

    return url;
  };

  const groupNotifications = (data: Notification[] = []): Notification[] => {
    const groupedNotifications: { [ticketId: number]: Notification[] } = {};

    data.forEach((notification) => {
      if (notification.ticket && notification.ticket.id) {
        const ticketId = notification.ticket.id;
        if (!groupedNotifications[ticketId]) {
          groupedNotifications[ticketId] = [];
        }
        groupedNotifications[ticketId].push(notification);
      }
      if (notification.bop && notification.bop.id) {
        const ticketId = notification.bop.id + 10000000000000;
        if (!groupedNotifications[ticketId]) {
          groupedNotifications[ticketId] = [];
        }
        groupedNotifications[ticketId].push(notification);
      }
    });

    const result: Notification[] = [];

    Object.values(groupedNotifications).forEach((groupedNotifs) => {
      if (groupedNotifs.length >= MIN_GROUPED_NOTIFICATIONS) {
        const unreads = groupedNotifs.filter(
          (notification) => !notification.isRead
        );
        const newestNotif =
          unreads.length > 0
            ? unreads.reduce((prev, current) =>
                prev.createdAt > current.createdAt ? prev : current
              )
            : groupedNotifs.reduce((prev, current) =>
                prev.createdAt > current.createdAt ? prev : current
              );
        const newNotif = { ...newestNotif };
        newNotif.count =
          unreads.length > 0 ? unreads.length : groupedNotifs.length;

        if (newNotif.count >= MIN_GROUPED_NOTIFICATIONS) {
          // newNotif.description = `Zgrupowane powiadomienia`;
          result.push(newNotif);
        } else if (unreads.length > 0) {
          unreads.forEach((notification) => {
            result.push(notification);
          });
        } else {
          groupedNotifs.forEach((notification) => {
            result.push(notification);
          });
        }
      } else {
        groupedNotifs.forEach((notification) => {
          result.push(notification);
        });
      }
    });

    data.forEach((notification) => {
      if (
        (!notification.ticket || !notification.ticket.id) &&
        (!notification.bop || !notification.bop.id)
      ) {
        result.push(notification);
      }
    });

    result.sort((a, b) => {
      if (a.isRead === b.isRead) {
        return b.createdAt - a.createdAt;
      }
      return a.isRead ? 1 : -1;
    });

    return result;
  };

  const groupedNotification = groupNotifications(notifications);

  const history = useHistory();

  const renderNotifications = (rows: Notification[]) => {
    if (!rows?.length) {
      return (
        <Empty
          description="Brak nowych powiadomień"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      );
    }
    return rows.slice(0, MAX_NOTIFICATIONS_ROWS).map((notification) => (
      <li
        key={notification.id}
        className={classNames([
          "notifications-list__item item",
          !notification.isRead && "item--unread",
        ])}
      >
        {notification.source === "ticket" && (
          <Link
            to={makeTicketUrl(notification)}
            replace
            className="item__link"
            onClick={() => {
              read(notification.id);
            }}
          >
            <TrimmedTitle
              title={`#${notification.ticket.displayId} - ${notification.ticket.title}`}
            />{" "}
            {notification.count && notification.count > 0 && (
              <span
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                (<BellOutlined style={{ marginRight: 2 }} />
                <span>× {notification.count})</span>
              </span>
            )}
          </Link>
        )}

        {notification.source === "bop" && (
          <Link
            to={makeBopUrl(notification)}
            replace
            className="item__link"
            onClick={() => {
              read(notification.id);
            }}
          >
            <TrimmedTitle
              title={`#${notification.bop.id} - BOP -  ${notification.bop.title}`}
            />{" "}
            {notification.count && notification.count > 0 && (
              <span
                style={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                (<BellOutlined style={{ marginRight: 2 }} />
                <span>× {notification.count})</span>
              </span>
            )}
          </Link>
        )}

        {notification.source === "archive" && (
          <Link
            to="/archive"
            replace
            className="item__link"
            onClick={() => {
              read(notification.id);
            }}
          >
            Archiwum
          </Link>
        )}

        {notification.source === "chat" && (
          <Link
            to={
              notification.chat
                ? `/czat/${notification.chat?.room?.id}/${notification.chat.id}`
                : "/czat"
            }
            replace
            className="item__link"
            onClick={() => {
              setVisible(false);
              read(notification.id);
            }}
          >
            Czat
          </Link>
        )}
        <p className="item__text">
          {notification.count && notification.count > 0 && (
            <span>Ost. akcja: </span>
          )}
          {notification.description}
        </p>
        <p className="item__date">
          {timeAgoFromTimestamp(notification.createdAt * 1000)} - od{" "}
          {notification.creator
            ? `${notification.creator.name} ${notification.creator.surname}`
            : "jobin"}
        </p>
      </li>
    ));
  };

  const notificationDropdown = (
    <Menu className="user-notifications__notifications-dropdown notifications-dropdown ">
      <div className="notifications-dropdown__notifications-header notifications-header">
        <div className="notifications-header__title">
          <BellOutlined className="notifications-header__icon" />
          <p>Powiadomienia</p>
        </div>
        <Button
          type="link"
          className="notifications-header__link"
          onClick={clear}
        >
          {!isClearing ? "Wyczyść" : <LoadingOutlined spin />}
        </Button>
      </div>
      <ul className="notifications-dropdown__notifications-list notifications-list">
        {renderNotifications(groupedNotification)}
      </ul>
      <div className="notifications-dropdown__notifications-footer notifications-footer">
        <Link to="/notifications" onClick={() => setVisible(false)}>
          Pokaż wszystkie
        </Link>
      </div>
    </Menu>
  );
  return (
    <div className="user-notifications">
      <Dropdown
        visible={visible}
        onVisibleChange={(visibleChange) => setVisible(visibleChange)}
        overlay={notificationDropdown}
        placement="bottomLeft"
        arrow
        trigger={["click"]}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="user-notifications__link"
          onClick={clearMetaTitle}
        >
          <Badge count={unread?.length} size="small">
            <BellOutlined style={{ fontSize: "20px" }} />
          </Badge>
        </a>
      </Dropdown>
    </div>
  );
};

export default UserNotification;
