import React, { PropsWithChildren } from "react";
import { Button, Space, Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
  FieldTimeOutlined,
  LockOutlined,
  UnlockOutlined,
  UserOutlined,
} from "@ant-design/icons";

import Text from "antd/es/typography/Text";
import moment from "moment";
import { formatMoney, formatSeconds } from "../../../utils/common";
import { UserFilledIcon } from "../UserFillled";
import { UserOutlinedIcon } from "../UserOutlined";
import TrimmedTitle from "../TrimmedTitle";
import { UserIcon } from "../UserIcon";

const List = (props: PropsWithChildren<any>): JSX.Element => {
  const { data, isRequest, users, total } = props;
  const columns = [
    {
      title: "Lp.",
      dataIndex: "title",
      key: "title",
      render: (title: string, worktime: any, index: number) => {
        const status = worktime?.ticket?.status_id.id;
        return index + 1;
      },
    },
    {
      title: "Tytuł",
      dataIndex: "title",
      className: "single-month__column--title",
      key: "title",
      render: (title: string, worktime: any) => {
        const status = worktime?.ticket?.status_id.id;
        return (
          <Link
            to={`/group/${1}/${worktime.ticket.id}`}
            className="jb-link jb-link--secendary"
          >
            <Space className="ant-space-break-words">
              {status === 8 || status === 9 ? (
                <LockOutlined color="red" style={{ color: "red" }} />
              ) : (
                <UnlockOutlined style={{ color: "green" }} />
              )}
              <Text>
                <TrimmedTitle
                  title={`#${worktime.ticket.displayId} - ${worktime.ticket.title}`}
                />
              </Text>
            </Space>
          </Link>
        );
      },
    },
    {
      title: "Dział",
      className: "single-month__column--title",
      dataIndex: "group",
      key: "group",
      render: (group: any, worktime: any) => {
        const { name, id } = worktime?.ticket?.group;
        return (
          <Space direction="vertical" className="ant-space-break-words">
            <Link to={`/group/${id}`} className="jb-link jb-link--secendary">
              {name}
            </Link>
          </Space>
        );
      },
    },
    {
      title: "Data rozpoczęcia",
      dataIndex: "dateStart",
      key: "dateStart",
      render: (dateStart: number, worktime: any) => (
        <Text>{moment(dateStart * 1000).format("DD.MM.Y HH:mm")}</Text>
      ),
    },
    {
      title: "Data zakończenia",
      dataIndex: "dateEnd",
      key: "dateEnd",
      render: (dateEnd: number, worktime: any) => (
        <Text>{moment(dateEnd * 1000).format("DD.MM.Y HH:mm")}</Text>
      ),
    },
    {
      title: "Użytkownik",
      className: "single-month__column--users",
      dataIndex: "user",
      key: "user",
      render: (user: any, worktime: any) => {
        const { typeAccount, name, surname, id } = user;
        return (
          <Space direction="vertical">
            <Space direction="horizontal">
              <UserIcon leaders={worktime.ticket?.group?.leaders} user={user} />
              <Space direction="horizontal">
                <span>
                  {name} {surname}
                </span>
              </Space>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Godz/Min/Sek",

      dataIndex: "seconds",
      key: "seconds",
      render: (seconds: string) => {
        return (
          <Tooltip title={formatSeconds(parseInt(seconds, 10), true)}>
            <Text>{formatSeconds(parseInt(seconds, 10))}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Szczegóły",
      dataIndex: "action",
      ellipsis: false,
      key: "action",
      render: (progress: string, worktime: any) => {
        const groupId = worktime.ticket.group.id;
        const { id } = worktime.ticket;
        return (
          <Link to={`/group/${groupId}/${id}/worktimes`}>
            <Button icon={<FieldTimeOutlined />} />
          </Link>
        );
      },
    },
  ];
  return (
    <Table
      className="single-month"
      style={{ width: "100%" }}
      scroll={{ x: true }}
      locale={{ emptyText: "Brak danych" }}
      size="small"
      dataSource={data}
      columns={columns}
      pagination={false}
      loading={isRequest}
      summary={() => (
        <>
          {total && total.seconds > 0 && (
            <>
              <Table.Summary.Row className="single-month__summary--total">
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2} />
                <Table.Summary.Cell index={3} />
                <Table.Summary.Cell index={4} />
                <Table.Summary.Cell index={5}>
                  <Text strong>Suma</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <Tooltip title={formatSeconds(total.seconds, true)}>
                    <Text strong>{formatSeconds(total.seconds)}</Text>
                  </Tooltip>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} />
                <Table.Summary.Cell index={8} />
                <Table.Summary.Cell index={9} />
              </Table.Summary.Row>
            </>
          )}
          {Object.values(users).map((userObj: any) => (
            <Table.Summary.Row className="single-month__summary--day">
              <Table.Summary.Cell index={1} />
              <Table.Summary.Cell index={2} />
              <Table.Summary.Cell index={3} />
              <Table.Summary.Cell index={4} />
              <Table.Summary.Cell index={5}>
                <Text strong style={{ textTransform: "capitalize" }}>
                  {userObj.user.name} {userObj.user.surname}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <Tooltip
                  title={formatSeconds(parseInt(userObj.seconds, 10), true)}
                >
                  <Text strong>
                    {formatSeconds(parseInt(userObj.seconds, 10))}
                  </Text>
                </Tooltip>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} />
              <Table.Summary.Cell index={8} />
              <Table.Summary.Cell index={9} />
            </Table.Summary.Row>
          ))}
        </>
      )}
      rowKey="id"
    />
  );
};

export default List;
