import React, { PropsWithChildren, useEffect } from "react";
import { Checkbox, Form, Input } from "formik-antd";
import { Alert, Button, Row, Space } from "antd";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

interface IAnswerStatus {
  isRequest: boolean;
  isTokenValid: boolean;
  isSent: boolean;

  inviteStatus: boolean | null;
}

const AnswerStatus: React.FC<IAnswerStatus> = (
  props: PropsWithChildren<IAnswerStatus>
): JSX.Element => {
  const { isTokenValid } = props;

  const { isSent, inviteStatus } = props;

  if (!isSent) return <></>;

  if (inviteStatus)
    return (
      <>
        <Alert
          message="Zaproszenie zaakceptowane"
          type="error"
          style={{ marginBottom: 8, maxWidth: 300 }}
        />

        <Space
          align="center"
          style={{ width: "100%", marginTop: 36 }}
          direction="vertical"
        >
          <Link to="/">
            <Button type="primary">Przejdź do logowania</Button>
          </Link>
        </Space>
      </>
    );

  return (
    <Alert
      message="Zaproszenie odrzucone"
      type="error"
      style={{ marginBottom: 8, maxWidth: 300 }}
    />
  );
};

export default AnswerStatus;
