import { Dispatch } from "redux";
import ChatService from "../../services/chat-service";

import {
  FETCH_MESSAGES,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILED,
  SET_NEW_MESSAGES,
  FETCH_NEW_MESSAGES_COUNT,
  FETCH_NEW_MESSAGES_COUNT_SUCCESS,
  FETCH_NEW_MESSAGES_COUNT_FAILED,
  FETCH_CHAT_USERS,
  FETCH_CHAT_USERS_SUCCESS,
  FETCH_CHAT_USERS_FAILED,
  POST_CHAT_MESSAGE,
  POST_CHAT_MESSAGE_SUCCESS,
  POST_CHAT_MESSAGE_FAILED,
  FETCH_NEW_MESSAGES_SUCCESS,
  FETCH_NEW_MESSAGES_FAILED,
  FETCH_NEW_MESSAGES,
  CLEAR_CHAT_MESSAGES,
  FETCH_ARCHIVE_MESSAGES,
  FETCH_ARCHIVE_MESSAGES_SUCCESS,
  FETCH_ARCHIVE_MESSAGES_FAILED,
  FETCH_CHAT_TICKETS,
  FETCH_CHAT_TICKETS_SUCCESS,
  FETCH_CHAT_TICKETS_FAILED,
  FETCH_CHAT_USER_TAGS,
  FETCH_CHAT_USER_TAGS_SUCCESS,
  FETCH_CHAT_USER_TAGS_FAILED,
  FETCH_LINK_MESSAGES,
  FETCH_LINK_MESSAGES_SUCCESS,
  FETCH_LINK_MESSAGES_FAILED,
  FETCH_CHAT_ATTACHMENTS,
  FETCH_CHAT_ATTACHMENTS_SUCCESS,
  FETCH_CHAT_ATTACHMENTS_FAILED,
  SET_CHAT_ATTACHMENTS,
  SET_SELECTED_CHAT_ROOM,
  FETCH_CHAT_ROOMS,
  FETCH_CHAT_ROOMS_SUCCESS,
  FETCH_CHAT_ROOMS_FAILED,
  POST_CHAT_REACTION,
  POST_CHAT_REACTION_SUCCESS,
  POST_CHAT_REACTION_FAILED,
} from "../action-types";

export const getMessages = (room: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_MESSAGES,
    payload: true,
  });
  return ChatService.getMessages(room).then(
    (response) => {
      dispatch({
        type: FETCH_MESSAGES_SUCCESS,
        payload: { data: response.data, room },
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_MESSAGES_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getLinkMessages = (room: string | number, id: number | string) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_LINK_MESSAGES,
    payload: true,
  });
  return ChatService.getLinkMessages(room, id).then(
    (response) => {
      dispatch({
        type: FETCH_LINK_MESSAGES_SUCCESS,
        payload: { data: response.data, room },
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_LINK_MESSAGES_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getArchiveMessages = (
  room: string | number,
  timestamp: number
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_ARCHIVE_MESSAGES,
    payload: true,
  });
  return ChatService.getMessagesArchive(room, timestamp).then(
    (response) => {
      dispatch({
        type: FETCH_ARCHIVE_MESSAGES_SUCCESS,
        payload: { data: response.data, room },
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_ARCHIVE_MESSAGES_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getChatAttachments = (room: string | number, id: number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_CHAT_ATTACHMENTS,
    payload: true,
  });
  return ChatService.getAttachments(room, id).then(
    (response) => {
      dispatch({
        type: FETCH_CHAT_ATTACHMENTS_SUCCESS,
        payload: { data: response.data, room },
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_CHAT_ATTACHMENTS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const setAttachments = (room: number | string, data: any[]) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({
    type: SET_CHAT_ATTACHMENTS,
    payload: { data, room },
  });
};

export const getNewMessages = (room: string | number) => (
  dispatch: Dispatch<any>
) => {
  return ChatService.getNewMessages(room).then(
    (response) => {
      dispatch({
        type: FETCH_NEW_MESSAGES_SUCCESS,
        payload: { data: response.data, room },
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const getNewMessagesCount = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_NEW_MESSAGES_COUNT,
    payload: true,
  });
  return ChatService.getNewMessagesCount().then(
    (response) => {
      dispatch({
        type: FETCH_NEW_MESSAGES_COUNT_SUCCESS,
        payload: {
          count: response.data?.count,
          rooms: response.data?.rooms,
          panels: response.data?.panels,
        },
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_NEW_MESSAGES_COUNT_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const setNewMessagesCount = (count: number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: SET_NEW_MESSAGES,
    payload: count,
  });
};

export const getChatUsers = (room: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_CHAT_USERS,
    payload: true,
  });
  return ChatService.getChatUsers(room).then(
    (response) => {
      dispatch({
        type: FETCH_CHAT_USERS_SUCCESS,
        payload: { data: response.data, room },
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_CHAT_USERS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getChatRooms = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_CHAT_ROOMS,
    payload: true,
  });
  return ChatService.getRooms().then(
    (response) => {
      dispatch({
        type: FETCH_CHAT_ROOMS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_CHAT_ROOMS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getChatTickets = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_CHAT_TICKETS,
    payload: true,
  });
  return ChatService.getChatTickets().then(
    (response) => {
      dispatch({
        type: FETCH_CHAT_TICKETS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_CHAT_TICKETS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getChatUserTags = (room: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_CHAT_USER_TAGS,
    payload: true,
  });
  return ChatService.getChatUserTags(room).then(
    (response) => {
      dispatch({
        type: FETCH_CHAT_USER_TAGS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_CHAT_USER_TAGS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const postChatMessage = (
  room: string | number,
  message: string,
  mentions: number[],
  tags: number[],
  notifyAll: boolean,
  attachments: []
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: POST_CHAT_MESSAGE,
    payload: true,
  });
  return ChatService.postMessage(
    room,
    message,
    mentions,
    tags,
    notifyAll,
    attachments
  ).then(
    (response) => {
      dispatch({
        type: POST_CHAT_MESSAGE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: POST_CHAT_MESSAGE_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const postChatReaction = (
  messageId: string | number,
  roomId: string | number,
  reaction: string
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: POST_CHAT_REACTION,
    payload: true,
  });
  return ChatService.postReaction(messageId, reaction).then(
    (response) => {
      dispatch({
        type: POST_CHAT_REACTION_SUCCESS,
        payload: { data: [response.data], room: roomId },
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: POST_CHAT_REACTION_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const setSelectedRoom = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: SET_SELECTED_CHAT_ROOM,
    payload: id,
  });
};

export const removeChatMessages = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  return ChatService.removeRoomMessages(id).then(
    (response) => {
      dispatch({
        type: CLEAR_CHAT_MESSAGES,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject();
    }
  );
};
