import React, { PropsWithChildren, useState } from "react";
import { Badge } from "antd";

interface IMultiBadgeProps {
  notifications: number;
  messages: number;
  type: string;
}
const MultiBadge = (props: any): JSX.Element => {
  const { notifications, messages, type, children } = props;

  if (type === "avatar") {
    if (notifications && messages) {
      return (
        <Badge count={notifications} size="small" offset={[0, 4]}>
          <Badge size="small" count={messages} offset={[0, 20]} color="orange">
            {children}
          </Badge>
        </Badge>
      );
    }

    if (notifications && !messages) {
      return (
        <Badge count={notifications} size="small" offset={[0, 4]}>
          {children}
        </Badge>
      );
    }
    if (!notifications && messages) {
      return (
        <Badge size="small" count={messages} color="orange" offset={[0, 4]}>
          {children}
        </Badge>
      );
    }
    return children;
  }

  if (notifications && messages) {
    return (
      <Badge count={notifications} size="small" offset={[0, 4]}>
        <Badge size="small" count={messages} offset={[0, 20]} color="orange">
          {children}
        </Badge>
      </Badge>
    );
  }

  if (notifications && !messages) {
    return (
      <Badge count={notifications} size="small">
        {children}
      </Badge>
    );
  }
  if (!notifications && messages) {
    return (
      <Badge size="small" count={messages} color="orange">
        {children}
      </Badge>
    );
  }
  return children;
};

export default MultiBadge;
