/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { Avatar, Badge, Button, Dropdown, Grid, Menu } from "antd";
import {
  BellOutlined,
  CaretRightOutlined,
  ClockCircleOutlined,
  LogoutOutlined,
  ProfileOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import classnames from "classnames";
import moment from "moment/moment";
import { makeAvatarName, stringToColour } from "../../../utils/common";
import { CalendarFilterOptions } from "../CustomFilters/CalendarPicker";
import MultiBadge from "../MultiBadge";
// import { makeAvatarName } from "../../utils/common";
// import { AccountType } from "../../types/user";

interface Company {
  id: number;
  name: string;
  img: string;
  uuid: string;
}

interface ICompanyPickerProps {
  companies: Company[];
  notifications: any[];
  messages: any[];
  currentCompany: Company;
}

const CompanyPicker = (props: ICompanyPickerProps): JSX.Element => {
  const { companies, currentCompany, notifications, messages } = props;

  const changeCompany = (uuid: string) => {
    localStorage.setItem("company_uuid", uuid);
    window.location.replace("/");
  };

  if (companies.length < 2) return <></>;

  return (
    <div className="company-picker">
      {companies.map((company, index) => {
        const notificationsCount = notifications
          .filter((notification) => notification.id === company.id)
          .reduce(
            (accumulator, currentValue) => accumulator + currentValue.count,
            0
          );

        const messagesCount = messages
          .filter((msg) => msg.id === company.id)
          .reduce(
            (accumulator, currentValue) => accumulator + currentValue.count,
            0
          );
        return (
          <div
            className={classnames({
              "company-picker__item": true,
              "company-picker__item--active": currentCompany.id === company.id,
            })}
            onClick={() => changeCompany(company.uuid)}
          >
            <MultiBadge
              notifications={notificationsCount}
              messages={messagesCount}
              type="avatar"
            >
              <Avatar
                key={123}
                size={40}
                src={company.img || null}
                className="user-app__avatar"
                style={{
                  color: "#fefefe",
                  backgroundColor: stringToColour(`${company.name}`),
                }}
              >
                {makeAvatarName(false, company.name)}
              </Avatar>
            </MultiBadge>
            <span className="company-picker__name">{company.name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default CompanyPicker;
