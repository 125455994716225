import React, { PropsWithChildren, useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Grid,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import {
  ClockCircleOutlined,
  EditOutlined,
  FieldTimeOutlined,
  MessageOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import classnames from "classnames";

import Text from "antd/es/typography/Text";
import moment from "moment";
import {
  makeAvatarName,
  renderLastActivity,
  stringToColour,
  timeAgoFromTimestamp,
} from "../../../utils/common";
import RemoveRecord from "../../Shared/RemoveRecord";
import useIsMobile from "../../../hooks/useIsMobile";

const { TabPane } = Tabs;

interface IUsersPageProps {
  users: IUserList[];
  isRequest: boolean;
  isDeleteRequest: boolean;
  onDelete: (user: any, callback: () => void) => void;
}

interface IUserList {
  id: string;
  email: string;
  username: string;
  name: string;
  tag: any;
  surname: string;
  description: string;
  invitationStatus: number;
  phone: string;
  avatar: string;
  img: string;
  lastActivity: {
    id: number;
    date: number;
  };
  typeAccount: number;
  role: IRole[];
  isOnline: boolean;
  isIdle: boolean;
}
interface IRole {
  id: number;
  name: string;
}

const UsersList = (props: PropsWithChildren<IUsersPageProps>): JSX.Element => {
  const { users, isRequest, onDelete } = props;

  const isMobile = useIsMobile();

  interface IUserAvatar {
    img: string;
    avatarColor: string | null;
    avatarBackground: string | null;
    name: string;
    surname: string;
    email: string;
  }

  const UserAvatar = (user: IUserAvatar) => {
    const { img, avatarColor, avatarBackground, name, surname, email } = user;
    return (
      <Avatar
        size={40}
        src={img || null}
        className="user-app__avatar user-app__avatar--user-list"
        style={{
          color: avatarColor || "white",
          backgroundColor: avatarBackground || stringToColour(email), // $complementary-blue
          borderColor: "#fefefe",
          borderWidth: 1,
          borderStyle: "solid",
          minWidth: 40,
        }}
      >
        {makeAvatarName(false, name, surname)}
      </Avatar>
    );
  };

  let columns = [
    {
      title: "",
      dataIndex: "img",
      key: "img",
      hidden: isMobile,
      render: (image: string, user: IUserList) => {
        const { img, name, surname, email } = user;
        return (
          <UserAvatar
            img={img}
            avatarColor={null}
            avatarBackground={null}
            name={name}
            surname={surname}
            email={email}
          />
        );
      },
      width: 60,
    },
    {
      title: "Imię Nazwisko",
      dataIndex: "name",
      className: "table__column--name",
      key: "name",
      minWidth: 130,
      hidden: isMobile,
      render: (name: string, user: IUserList) => {
        if (!name) return "";
        if (user.invitationStatus === 3 || user.invitationStatus === 1) {
          return (
            <>
              <Tooltip
                title={
                  user.invitationStatus === 1
                    ? "Wysłano zaproszenie"
                    : "Odrzucono zaproszenie"
                }
              >
                <Badge
                  status={user.invitationStatus === 1 ? "warning" : "error"}
                />
              </Tooltip>
              {name} {user.surname}
            </>
          );
        }
        return `${name} ${user.surname}`;
      },
      ellipsis: true,
      sorter: (a: IUserList, b: IUserList) => {
        if (!a.name || !b.name) return -1;
        const aName = `${a.name} ${a.surname}`;
        const bName = `${b.name} ${b.surname}`;
        return aName.localeCompare(bName);
      },
      showSorterTooltip: false,
    },
    {
      title: "Imię Nazwisko",
      dataIndex: "name",
      className: "table__column--user-table-info user-table-info",
      key: "nameMobile",
      hidden: !isMobile,
      render: (name: string, user: IUserList) => {
        const lastActive = user?.lastActivity?.date;
        return (
          <div className="user-table-info__container">
            <div className="user-table-info__header">
              <UserAvatar
                img={user.img}
                avatarColor={null}
                avatarBackground={null}
                name={name}
                surname={user.surname}
                email={user.email}
              />
              <div style={{ width: "100%" }}>
                <div className="user-table-info__name-row">
                  <p className="user-table-info__name">
                    {name} {user.surname}
                  </p>
                  <Space size="small" align="end">
                    <Tooltip title="Edytuj" mouseEnterDelay={1}>
                      <Link to={`/users/edit/${user.id}`}>
                        <Button icon={<EditOutlined />} size="small" />
                      </Link>
                    </Tooltip>
                    <RemoveRecord
                      title="Czy napewno chcesz usunąć użytkownika"
                      onConfirm={(callbackHidePopover: () => void) =>
                        onDelete(user, callbackHidePopover)
                      }
                      okText="Usuń"
                      cancelText="Anuluj"
                      tooltipText="Usuń"
                      size="small"
                    />
                  </Space>
                </div>

                <p className="user-table-info__email">{user.email}</p>
                <Space wrap className="ant-space-break-words">
                  {/* eslint-disable-next-line react/destructuring-assignment */}
                  {user.tag?.map((tag: any) => {
                    return (
                      <Tag className="table__column--tag-item">{tag.name}</Tag>
                    );
                  })}
                </Space>
              </div>
            </div>
          </div>
        );
      },
      sorter: (a: IUserList, b: IUserList) => {
        if (!a.name || !b.name) return -1;
        const aName = `${a.name} ${a.surname}`;
        const bName = `${b.name} ${b.surname}`;
        return aName.localeCompare(bName);
      },
      showSorterTooltip: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "table__column--email",
      ellipsis: true,
      hidden: isMobile,
      sorter: (a: IUserList, b: IUserList) => {
        return a.email.localeCompare(b.email);
      },
      showSorterTooltip: false,
    },
    {
      title: "Ustawienia konta",
      dataIndex: "role",
      className: "table__column--role",
      key: "role",
      ellipsis: false,
      hidden: isMobile,
      filters: [
        { text: "Logowanie aktywne", value: 1 },
        { text: "Czat aktywny", value: 24 },
        { text: "Wymuszaj wybranie czasu realizacji", value: 9 },
        { text: "Włącz autostart czasu", value: 11 },
      ],
      onFilter: (value: string | number | boolean, user: IUserList) =>
        user.role.map((r) => r.id).includes(Number(value)),
      render: (role: IRole[], user: IUserList) => {
        return (
          <div className="permissions-icons">
            <Tooltip title="Logowanie aktywne" mouseEnterDelay={1}>
              <UnlockOutlined
                className={classnames({
                  "permissions-icons__icon": true,
                  // eslint-disable-next-line react/destructuring-assignment
                  "permissions-icons__icon--active": role
                    .map(({ name }) => name)
                    .includes("ENABLED_LOGIN"),
                })}
              />
            </Tooltip>
            <Tooltip title="Czat aktywny" mouseEnterDelay={1}>
              <MessageOutlined
                className={classnames({
                  "permissions-icons__icon": true,
                  // eslint-disable-next-line react/destructuring-assignment
                  "permissions-icons__icon--active": role
                    .map(({ name }) => name)
                    .includes("ENABLED_CHAT"),
                })}
              />
            </Tooltip>
            <Tooltip
              title="Wymuszaj wybranie czasu realizacji"
              mouseEnterDelay={1}
            >
              <ClockCircleOutlined
                className={classnames({
                  "permissions-icons__icon": true,
                  // eslint-disable-next-line react/destructuring-assignment
                  "permissions-icons__icon--active": role
                    .map(({ name }) => name)
                    .includes("END_TIME"),
                })}
              />
            </Tooltip>
            <Tooltip title="Włącz autostart czasu" mouseEnterDelay={1}>
              <FieldTimeOutlined
                className={classnames({
                  "permissions-icons__icon": true,
                  "permissions-icons__icon--big": true,
                  // eslint-disable-next-line react/destructuring-assignment
                  "permissions-icons__icon--active": role
                    .map(({ name }) => name)
                    .includes("AUTO_START_TIME"),
                })}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Etykiety",
      dataIndex: "tag",
      className: "table__column--tag",
      hidden: isMobile,
      key: "tag",
      render: (tags: any[], record: any) => {
        return (
          <Space wrap className="ant-space-break-words">
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {tags?.map((tag) => {
              return <Tag className="table__column--tag-item">{tag.name}</Tag>;
            })}
          </Space>
        );
      },
    },
    {
      title: "Ost. aktywny",
      dataIndex: "lastActivity",
      key: "lastActivity",
      className: "table__column--last-activity",
      ellipsis: true,
      hidden: isMobile,
      render: (lastActivity: { date: number } | null, record: any) => {
        if (!lastActivity) return "Brak";

        const { date } = lastActivity;
        return (
          <Text>
            <Row>
              <Col span={24}>
                <small>
                  {record.lastActivity
                    ? renderLastActivity(lastActivity)
                    : null}
                </small>
              </Col>
              <Col span={24}>
                <small>
                  {date
                    ? moment(date * 1000).format("DD.MM.YYYY HH:mm")
                    : "Brak"}
                </small>
              </Col>
            </Row>
          </Text>
        );
      },
      sorter: (a: IUserList, b: IUserList) => {
        if (!a.lastActivity) return -1;
        return a.lastActivity?.date - b.lastActivity?.date;
      },
      showSorterTooltip: false,
    },
    {
      title: "Akcje",
      key: "action",
      className: "table__column--actions",
      width: 100,
      hidden: isMobile,
      fixed: "right" as const,
      render: (text: string, record: IUserList) => (
        <Space size="middle">
          <Tooltip title="Edytuj" mouseEnterDelay={1}>
            <Link to={`/users/edit/${record.id}`}>
              <Button icon={<EditOutlined />} />
            </Link>
          </Tooltip>
          <RemoveRecord
            title="Czy napewno chcesz usunąć użytkownika"
            onConfirm={(callbackHidePopover: () => void) =>
              onDelete(record, callbackHidePopover)
            }
            okText="Usuń"
            cancelText="Anuluj"
            tooltipText="Usuń"
          />
        </Space>
      ),
    },
  ];

  columns = columns.filter((row: any) => row.hidden === false);

  return (
    <Table
      size={isMobile ? "small" : "middle"}
      locale={{ emptyText: "Brak danych" }}
      dataSource={users}
      className="users-page__table table"
      scroll={{ x: isMobile ? 0 : 1000 }}
      columns={columns}
      pagination={{ position: ["bottomRight"] }}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default UsersList;
