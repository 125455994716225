import { Badge, Col, Row } from "antd";
import {
  BookOutlined,
  DollarCircleOutlined,
  LockOutlined,
  PhoneOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";
import TicketTypeForm from "../../Pages/Ticket/QuickForms/TicketTypeForm";
import TicketType from "../TicketType";
import TrimmedTitle from "../TrimmedTitle";
import { TicketStatus } from "../../../types/ticket";

interface ISearchGroup {
  group: any;
  onClick: () => void;
}

const SearchGroup = (props: ISearchGroup) => {
  const { group, onClick } = props;

  return (
    <Row gutter={[8, 8]} align="middle" style={{ padding: 4, minHeight: 33 }}>
      <Link
        to={`/group/${group.id}`}
        className="jb-link jb-link--secendary"
        onClick={onClick}
      >
        <span className="">
          {group.deleted && (
            <LockOutlined style={{ color: "red", marginRight: 4 }} />
          )}
          <TrimmedTitle title={`#${group.displayId} - ${group.name}`} />
        </span>
      </Link>
    </Row>
  );
};

export default SearchGroup;
