import { Avatar, Badge, Button, Space, Tooltip } from "antd";
import classnames from "classnames";
import React from "react";
import Text from "antd/lib/typography/Text";
import {
  makeAvatarName,
  stringToColour,
  timeAgoFromTimestamp,
} from "../../../utils/common";

interface IProps {
  room: any;
  isMobile: boolean;
  selectedRoom: number | null;
  count: number;
  selectRoom: (id: string | number) => void;
}

const ChatRoom = (props: IProps): JSX.Element => {
  const { room, isMobile, selectedRoom, selectRoom, count } = props;

  const getUsersStatus = (usersCount: number, activeCount: number) => {
    const countText = usersCount !== 1 ? "członków" : "członek";
    const activeText = activeCount !== 1 ? "aktywnych" : "aktywny";

    if (!activeCount) return `${usersCount} ${countText}`;

    return `${usersCount} ${countText}, ${activeCount} ${activeText}`;
  };

  return (
    <Button
      type={selectedRoom === room.id ? "primary" : "text"}
      block
      style={{ textAlign: "start" }}
      className="chat__room-btn"
      onClick={() => selectRoom(room.id)}
    >
      {/* <Badge count={count} size="small" offset={[-5, 0]} /> */}
      {/* <Space> */}
      {/*  <Text */}
      {/*    ellipsis */}
      {/*    style={{ */}
      {/*      maxWidth: 240, */}
      {/*      color: selectedRoom === room.id ? "white" : "black", */}
      {/*    }} */}
      {/*  > */}
      {/*    {room.name} */}
      {/*  </Text> */}
      {/* </Space> */}
      <Space
        wrap={false}
        align="center"
        className="chat__room-btn"
        style={{
          width: "100%",
        }}
      >
        <Avatar
          size={true ? 26 : 40}
          src={room.img || null}
          className={classnames({
            "user-app__avatar": true,
            user__avatar: true,
          })}
          style={{
            fontSize: 14,
            boxSizing: "content-box",
            color: "#fefefe",
            backgroundColor: stringToColour(room.name),
          }}
        >
          {makeAvatarName(false, room.name)}
        </Avatar>
        <div>
          <p className="chat__room-name">
            <Badge
              size="small"
              count={count}
              offset={[-5, 0]}
              style={{ backgroundColor: "orange" }}
            />
            {room.name}
          </p>
          <p
            className={classnames({
              "chat__room-status": true,
              "chat__room-status--active": selectedRoom === room.id,
            })}
          >
            {getUsersStatus(room.usersCount, room.usersOnlineCount)}
          </p>
        </div>
      </Space>
    </Button>
  );
};

export default ChatRoom;
