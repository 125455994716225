import React, { cloneElement, PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../reducers";
import { AccountType } from "../../../types/user";
import NoPermissions from "../NoPermissions";
import { TicketStatus, TicketValuationStatus } from "../../../types/ticket";

type PropsType = {
  children: JSX.Element;
  type: string;
  roles?: any[];
  renderError?: boolean;
  entity?: any;
};

const hasPermission = ({
  userId,
  permissions,
  accountType,
  type,
  forbidden,
  entity,
}: any) => {
  switch (type) {
    case "loading":
      return true;
    case "ticket_group_view":
      return !forbidden || accountType === AccountType.Administrator;
    case "ticket_group_worktimes_view":
      return !forbidden || accountType === AccountType.Administrator;
    case "ticket_group_worktimes_view_button": {
      const hasStatisticSummaryPermission = permissions.find(
        (permission: any) => permission.name === "STATISTIC_SUMMARY"
      );
      return (
        hasStatisticSummaryPermission ||
        accountType === AccountType.Administrator
      );
    }
    case "ticket_group_worktimes_summary": {
      const hasStatisticSummaryPermission = permissions.find(
        (permission: any) => permission.name === "STATISTIC_SUMMARY"
      );
      return (
        hasStatisticSummaryPermission ||
        accountType === AccountType.Administrator
      );
    }
    case "ticket_view":
      return !forbidden || accountType === AccountType.Administrator;
    case "ticket_worktimes_view":
      return !forbidden || accountType === AccountType.Administrator;
    case "ticket_create": {
      if (!entity?.id || entity?.deleted) return false;

      if (accountType === AccountType.Administrator) return true;

      const isLeader = entity?.leaders?.find(
        (leaders: any) => leaders.user.id === userId
      );

      if (isLeader) return true;

      const hasCreateTicketRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_CREATE_TICKET"
      );
      if (hasCreateTicketRole) {
        const isMember = entity?.members?.find(
          (member: any) => member.user.id === userId
        );
        if (isMember) return true;
      }

      const hasCreateTicketAllRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_CREATE_TICKET_ALL"
      );

      if (hasCreateTicketAllRole) {
        if (entity.userHasTasks) {
          return true;
        }
      }
      return false;
    }
    case "ticket_close": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const isReporter = entity?.reporter.id === userId;
      if (isReporter) {
        const hasCloseOwnTicketRole = permissions.find(
          (permission: any) => permission.name === "ENABLED_CLOSE_OWN_TICKET"
        );
        if (hasCloseOwnTicketRole) {
          return true;
        }
      }

      const hasCloseTicketRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_DELETE_TICKET"
      );
      if (hasCloseTicketRole) {
        return true;
      }
      return false;
    }
    case "ticket_edit": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const isReporter = entity?.reporter.id === userId;
      if (isReporter) {
        const hasEditeOwnTicketRole = permissions.find(
          (permission: any) => permission.name === "ENABLED_EDIT_OWN_TICKET"
        );
        if (hasEditeOwnTicketRole) {
          return true;
        }
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_EDIT_TICKET"
      );

      return hasRole;
    }
    case "ticket_open": {
      if (entity?.status_id?.id < TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRestoreRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_RESTORE_TICKET"
      );
      if (hasRestoreRole) {
        return true;
      }
      return false;
    }
    case "ticket_edit_progress": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      // const isReporter = entity?.reporter === userId;
      // if (isReporter) return true;
      //
      // const isLeader = entity?.leaders?.find(
      //   (leader: any) => leader.user.id === userId
      // );
      // if (isLeader) return true;
      //
      // const isAssigned = entity?.assignedUsers?.find(
      //   (assigned: any) => assigned.id === userId
      // );
      // if (isAssigned) return true;

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLE_EDIT_PROGRESS"
      );
      return hasRole;
    }

    case "due_date_required": {
      const dueDateEnabled = permissions.find(
        (permission: any) => permission.name === "ENABLED_TICKER_DUE_DATE"
      );

      const required = permissions.find(
        (permission: any) => permission.name === "END_TIME"
      );

      return dueDateEnabled && required;
    }

    case "ticket_unread": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      return true;
    }

    case "ticket_leave": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      const isAssigned = entity?.assignedUsers?.find(
        (assigned: any) => assigned.id === userId
      );

      if (isAssigned) {
        if (accountType === AccountType.Administrator) {
          return true;
        }

        const hasRole = permissions.find(
          (permission: any) => permission.name === "ENABLED_TICKET_LEAVE"
        );

        return hasRole;
      }
      return false;
    }
    case "ticket_edit_estimation": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasEstimationRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_ESTIMATION"
      );
      return hasEstimationRole;
    }
    case "ticket_edit_priority": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasEstimationRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_CHANGE_PRIORITY"
      );
      if (hasEstimationRole) {
        return true;
      }

      return false;
    }
    case "ticket_edit_highlight": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasEstimationRole = permissions.find(
        (permission: any) =>
          permission.name === "ENABLED_CHANGE_HIGHLIGHT_FAVORITE"
      );
      if (hasEstimationRole) {
        return true;
      }

      return false;
    }
    case "ticket_send_sms": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasSendSmsRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_SENDSMS"
      );
      return hasSendSmsRole;
    }
    case "ticket_edit_duo_date": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_TICKER_DUE_DATE"
      );
      return hasRole;
    }
    case "ticket_edit_cost": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_COST"
      );
      return hasRole;
    }

    case "ticket_valuation_answer": {
      if (entity?.valuationStatus?.id !== TicketValuationStatus.NEW)
        return false;

      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (!entity?.paid && !entity.cost) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_COST_ACCEPT"
      );
      return hasRole;
    }

    case "ticket_can_view_valuation": {
      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasAcceptRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_COST_ACCEPT"
      );

      const hasCostRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_COST"
      );

      return hasAcceptRole || hasCostRole;
    }

    case "ticket_can_assign_users": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ASSIGN_CUSTOMERS"
      );
      return hasRole;
    }

    case "ticket_can_assign_leaders": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ASSIGN_CUSTOMERS"
      );

      if (hasRole) {
        const isLeader = entity?.leaders?.find(
          (leader: any) => leader.user.id === userId
        );
        if (isLeader) return true;
      }
      return false;
    }

    case "ticket_new_valuation": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (entity?.valuationStatus?.id === TicketValuationStatus.NEW)
        return false;

      if (!entity?.paid) return false;

      if (entity?.cost) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_COST"
      );
      return hasRole;
    }

    case "ticket_hidden_comments": {
      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_HIDDEN_COMMENTS"
      );
      return hasRole;
    }

    case "ticket_update_history": {
      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_TICKET_HISTORY"
      );
      return hasRole;
    }

    case "ticket_edit_type": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLE_EDIT_TICKET_TYPE"
      );
      return hasRole;
    }

    case "ticket_comment": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      return true;
    }

    case "bop_comment": {
      if (entity?.status?.id > 1) return false;

      return true;
    }
    case "bop_attachment": {
      if (entity?.status?.id > 1) return false;

      return true;
    }

    case "bop_close": {
      if (entity?.status?.id > 1) return false;

      return true;
    }

    case "ticket_group_display_notes": {
      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "SHOW_NOTES"
      );
      return hasRole;
    }

    case "user_forward": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_USER_FORWARD"
      );
      return hasRole;
    }

    case "ticket_group_edit_notes": {
      if (!entity?.id || entity?.deleted) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "EDIT_NOTES"
      );
      return hasRole;
    }

    case "ticket_group_edit": {
      if (!entity?.id || entity?.deleted) return false;

      return accountType === AccountType.Administrator;
    }

    case "ticket_group_edit_announcement": {
      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLE_EDIT_ANNOUNCEMENT"
      );
      return hasRole;
    }

    case "worktime_comment_required": {
      const hasRole = permissions.find(
        (permission: any) => permission.name === "COMMENT_WORK_TIME"
      );
      return hasRole;
    }

    case "worktime_add_time": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ADD_TIME_OPT"
      );
      return hasRole;
    }

    case "worktime_start": {
      if (entity?.status_id?.id >= TicketStatus.CLOSED) return false;

      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_START_TIME"
      );

      if (hasRole) {
        const isReporter = entity?.reporter?.id === userId;
        const isAssigned = entity?.assignedUsers?.find(
          (assigned: any) => assigned.id === userId
        );
        return isAssigned || isReporter;
      }

      return false;
    }

    case "has_start_time_permission": {
      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_START_TIME"
      );

      return hasRole;
    }

    case "ticket_view_worktime_short": {
      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "STATISTIC_DETAIL"
      );
      return hasRole;
    }

    case "open_send_email": {
      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasRole = permissions.find(
        (permission: any) => permission.name === "OPEN_SEND_EMAIL"
      );
      return hasRole;
    }

    case "view_estimation": {
      if (accountType === AccountType.Administrator) {
        return true;
      }

      const hasViewRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_DISPLAY_ESTIMATION"
      );

      const hasEditRole = permissions.find(
        (permission: any) => permission.name === "ENABLED_ESTIMATION"
      );
      return hasEditRole || hasViewRole;
    }

    case "chat": {
      const hasChatPermission = permissions.find(
        (permission: any) => permission.name === "ENABLED_CHAT"
      );
      return hasChatPermission || accountType === AccountType.Administrator;
    }
    case "bop_view": {
      const hasBopPermission = permissions.find(
        (permission: any) => permission.name === "BOP_ENABLED"
      );
      return hasBopPermission || accountType === AccountType.Administrator;
    }

    case "bop_create": {
      const hasBopCreatePermission = permissions.find(
        (permission: any) => permission.name === "BOP_CREATE"
      );
      return (
        hasBopCreatePermission || accountType === AccountType.Administrator
      );
    }

    default:
      return accountType === AccountType.Administrator;
  }

  return false;
};

const Can = (props: PropsWithChildren<PropsType>): JSX.Element | null => {
  const user = useSelector((state: AppState) => state.auth.logged);
  const forbidden = useSelector(
    (state: AppState) => state.global.currentEntityForbidden
  );

  const { roles, type, children, renderError, entity, ...rest } = props;
  const accountType = user?.typeAccount;
  const permissionGranted = hasPermission({
    userId: user?.id,
    permissions: user?.role,
    accountType,
    type,
    forbidden,
    entity,
  });

  if (!permissionGranted && renderError) return <NoPermissions />;

  if (!permissionGranted) return null;

  return cloneElement(children, { ...rest });
};

export default Can;
