/* eslint-disable */
import React, {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { Col, Modal, Row, Tabs } from "antd";
import { Formik, FormikErrors, FormikValues } from "formik";
import { DatePicker, Form, Input } from "formik-antd";
import ReactQuill from "react-quill-2";
import * as Yup from "yup";
import {
  defaultEditorFormats,
  defaultEditorModules,
} from "../../../../utils/common";
import { createTicket } from "../../../../actions/tickets";
import Can from "../../../Shared/Can";
import moment, { Moment } from "moment";
import QuillEditor from "../../../Shared/Editor";
import useKeyPress from "../../../../hooks/useKeyPress";

const { TabPane } = Tabs;

interface INewQuickTicketProps {
  groupId: number | null;
  visible: boolean;
  onSubmit: (
    values: INewTicketQuickFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => void;
  onCancel: () => void;
}

export interface INewTicketQuickFormValues {
  title: string;
  description: string;
  group: number | null;
  dueDate: number | null;
}

const NewQuickTicketForm = (
  props: PropsWithChildren<INewQuickTicketProps>
): JSX.Element => {
  const { groupId, visible, onCancel, onSubmit } = props;

  const onSave = (
    values: INewTicketQuickFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    onSubmit(
      {
        ...values,
        dueDate: values.dueDate ? moment(values.dueDate).unix() : null,
      },
      callbackRequestCompleted,
      setFormErrors
    );
  };

  const initialFormValues: INewTicketQuickFormValues = {
    title: "",
    description: "",
    group: groupId,
    dueDate: null,
  };

  const dueDateRequired = !!Can({
    type: "due_date_required",
    children: <></>,
  });

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({
    title: Yup.string().required("Pole wymagane"),
    dueDate: dueDateRequired
      ? Yup.mixed().required("Termin jest wymagany")
      : Yup.mixed().nullable(),
  });

  const [formData, setFormData] =
    useState<INewTicketQuickFormValues>(initialFormValues);

  const [editRequest, setEditRequest] = useState<boolean>(false);

  const disabledDate = (current: Moment) => {
    return current && current < moment().startOf("day");
  };

  useKeyPress("ctrl", ["enter"], () => {
    const formValues = formikRef.current?.values;
    if (formValues?.title) {
      setEditRequest(true);
      onSave(
        formikRef.current?.values,
        () => {
          setEditRequest(false);
          formikRef.current?.resetForm();
        },
        formikRef.current?.setErrors
      );
    }
  });

  useEffect(() => {
    setFormData({ ...formData, group: groupId });
  }, [groupId]);

  useEffect(() => {
    if (visible) {
      formikRef.current?.resetForm();
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      destroyOnClose
      title="Nowy ticket"
      okText="Zapisz"
      cancelText="Anuluj"
      onCancel={() => {
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
        onCancel();
      }}
      confirmLoading={editRequest}
      onOk={() => {
        if (formikRef.current) {
          formikRef.current.handleSubmit();
        }
      }}
    >
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={formData}
        enableReinitialize
        validationSchema={FormSchema}
        onSubmit={(
          values: INewTicketQuickFormValues,
          { resetForm, setErrors }
        ) => {
          setEditRequest(true);
          onSave(
            values,
            () => {
              setEditRequest(false);
              resetForm();
            },
            setErrors
          );
        }}
        validateOnChange
        render={(formProps) => (
          <Form>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Tytuł"
                  name="title"
                  required
                  labelCol={{ span: 24 }}
                  rules={[{ required: true }]}
                >
                  <Input name="title" autoFocus />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Opis"
                  name="description"
                  labelCol={{ span: 24 }}
                >
                  <QuillEditor
                    initValue={""}
                    withMedia
                    reinitialize={visible}
                    value={formProps.values.description}
                    onChange={(change: string) => {
                      formProps.setFieldValue("description", change);
                    }}
                  />
                </Form.Item>
              </Col>
              <Can type="due_date_required">
                <Col xs={24} md={24} lg={12} xl={10}>
                  <Form.Item
                    name="dueDate"
                    label="Termin"
                    required={dueDateRequired}
                    labelCol={{ span: 24 }}
                  >
                    <DatePicker
                      getPopupContainer={(trigger) =>
                        trigger.parentElement?.parentElement as HTMLElement
                      }
                      format="DD.MM.Y HH:mm"
                      showTime={{ showSecond: false }}
                      name="dueDate"
                      allowClear
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </Col>
              </Can>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

const mapDispatchToProps = {
  createTicketAction: createTicket,
};

export default connect(null, mapDispatchToProps)(NewQuickTicketForm);
